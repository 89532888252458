import {
  ApeironBlueButton,
  ChainAddressType,
  CustomError,
  isIosOrAndroid,
  isOkxApp,
  NextImage,
  OkxSVG,
  RoninSVG,
  useAccountContext,
  WalletType
} from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import { ERROR_CODE } from '@src/constants/error'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  padding: 40px;

  ${props => props.theme.breakpoints.down('res768')} {
    margin-top: 60px;
  }
`

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`

const RoninWalletButton = styled(ApeironBlueButton)`
  --background-color: #1473e9;
  max-height: 50px;
  border-radius: 4px;
  color: white;
  flex: 1;
  font-size: 16px;
  gap: 6px;
  height: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  width: 100%;
  :hover {
    filter: brightness(0.9);
  }
`

const RoninWalletIcon = styled(NextImage)`
  filter: grayscale(100%) brightness(3);
  width: 24px;
  height: 24px;
`

const OkxWalletButton = styled(RoninWalletButton)`
  --background-color: white;
  color: black;
  flex: 0.5;
  min-width: 140px;
`

const OkWalletIcon = styled(NextImage)`
  width: 24px;
  height: 24px;
`

const SelectWalletSection = ({ onRoninLoginClick }: Props) => {
  const { t } = useTranslation()

  const { loginByWallet, setWrongNetwork } = useAccountContext()

  const handleOnOkxLogin = useCallback(async () => {
    try {
      if (isIosOrAndroid() && !isOkxApp()) {
        const encodedUrl =
          'https://www.okx.com/download?deeplink=' +
          encodeURIComponent(
            'okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href)
          )

        window.location.href = encodedUrl
      } else {
        await loginByWallet(WalletType.Okx, ChainAddressType.RONIN)
      }
    } catch (e) {
      const error = e as CustomError

      if (error.code === ERROR_CODE.LOGIN.WRONG_NETWORK) {
        setWrongNetwork(WalletType.Okx)
      }
    }
  }, [loginByWallet, setWrongNetwork])

  return (
    <Container>
      <Header>{t('login.select_wallet.header')}</Header>
      {!isOkxApp() && (
        <RoninWalletButton onClick={onRoninLoginClick}>
          <RoninWalletIcon src={RoninSVG} />
          {t('login.general.button.ronin')}
        </RoninWalletButton>
      )}
      <OkxWalletButton onClick={handleOnOkxLogin}>
        <OkWalletIcon src={OkxSVG} />
        {t('login.general.button.okx')}
      </OkxWalletButton>
    </Container>
  )
}

type Props = {
  onRoninLoginClick: () => void
}

export default SelectWalletSection
