import useMakeApostleTicket from '@src/deserialize/hook/useMakeApostleTicket'
import { FrontendApostleSeasonInfo } from '@src/types/config'

const useMakeApsotleMintConfig = () => {
  const { makeApostleTicket } = useMakeApostleTicket()

  const makeApostleMintConfig = (
    frontendConfig: any
  ): FrontendApostleSeasonInfo[] => {
    const apostleMintInfos = frontendConfig.mint_config.apostle

    const result = apostleMintInfos.map((info: any) => {
      return {
        season: info.season,
        seasonMintType: info.season_mint_type,
        forgePricesByStage: info.forge_price
          ? info.forge_price.map((stageInfo: any[]) => {
              return stageInfo.map((priceInfo: any) => {
                return {
                  currencyCode: priceInfo.currency_code,
                  value: priceInfo.value,
                  name: priceInfo.name
                }
              })
            })
          : undefined,
        tickets: info.ticket_id.map(makeApostleTicket),
        maxReserve: info.reserved_slot_max_count,
        mintCooldown: info.reserve_slot_update_time_limit_in_day,
        maxTicketSelect: info.ticketing_mint_max_count,
        beginTime: info.begin_time,
        endTime: info.end_time,
        maxClassLimit: info.max_class_limit,
        prices: info.price_list
          ? info.price_list.map((price: any) => {
              return {
                currencyCode: price.currency_code,
                price: price.price
              }
            })
          : undefined,
        reforgePriceGroup: info.reforge_price_default_group || '',
        reforgePriceConfigs: (info.reforge_price || []).map((config: any) => {
          return {
            priceGroups: config.price.map((groupConfig: any) => {
              return {
                priceGroup: groupConfig.group,
                prices: groupConfig.currencies.map((priceConfig: any) => {
                  return {
                    code: priceConfig.currency_code,
                    value: priceConfig.value
                  }
                })
              }
            }),
            reforgeCount: config.reforge_count
          }
        }),
        apostleSealedDays: info.reserve_slot_update_time_limit_in_day
      } satisfies FrontendApostleSeasonInfo
    })

    return result
  }

  return {
    makeApostleMintConfig
  }
}

export default useMakeApsotleMintConfig
