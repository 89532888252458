import { WalletType } from '@apeiron/library'
import ENV_CONFIG from '@src/config'

export const WalletInfo = {
  [WalletType.Metamask]: {
    nameKey: 'wallet.name.metamask',
    download: {
      chrome: ENV_CONFIG.EXTERNAL_URL.WALLET.METAMASK.CHROME,
      firefox: ENV_CONFIG.EXTERNAL_URL.WALLET.METAMASK.FIREFOX
    }
  },
  [WalletType.Ronin]: {
    nameKey: 'wallet.name.ronin',
    download: {
      chrome: ENV_CONFIG.EXTERNAL_URL.WALLET.RONIN.CHROME,
      firefox: ENV_CONFIG.EXTERNAL_URL.WALLET.RONIN.FIREFOX
    }
  }
}

export enum MobileWalletSnackBarMode {
  Connect = 'connect',
  SignMessage = 'sign-message',
  SignTransaction = 'sign-transaction',
  None = 'none'
}
