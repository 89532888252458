import {
  ApeironBlueButton,
  NextImage,
  RoninSVG,
  useAccountContext,
  useMessageContext
} from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import MailSVG from '@public/icons/icon-mail.svg'
import WalletSVG from '@public/icons/icon-wallet.svg'
import BindEmailButton from '@src/components/account/header/EmailBinder'
import ApeironEllipsisTypography from '@src/components/share/apeiron/ApeironEllipsisTypography'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(ApeironBlueButton)`
  width: 100%;
  gap: 4px;
  padding: 4px 12px;
`

const EmailBinderButton = styled(BindEmailButton)`
  gap: 8px;
`

const IconContainer = styled(Box)`
  height: 100%;
  aspect-ratio: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: circle(50%);
`

const Icon = styled(NextImage)`
  aspect-ratio: 1;
  height: 70%;
`

const Text = styled(Typography)`
  font-size: 12px;
  text-decoration: underline;
`

const WalletAddress = styled(ApeironEllipsisTypography)`
  width: 100%;
  font-size: 12px;
`

const WalletBlueButton: React.FC<Props> = props => {
  const { className } = props

  const { t } = useTranslation()

  const { setSnackInfo } = useMessageContext()

  const { account, setShowBindWalletPopup } = useAccountContext()

  const handleOnWalletClick = useCallback(() => {
    navigator.clipboard.writeText(account.walletAddress)
    setSnackInfo({
      message: t('common.detail.share.copy_message'),
      serverity: 'success'
    })
  }, [account.walletAddress, setSnackInfo, t])

  const handleOnBindWalletClick = useCallback(() => {
    setShowBindWalletPopup(true)
  }, [setShowBindWalletPopup])

  if (!account?.email) {
    return (
      <EmailBinderButton
        className={className}
        customButtonContent={
          <>
            <Icon src={MailSVG} />
            <Text>{t('account_menu.bind_email')}</Text>
          </>
        }
      />
    )
  }

  if (!account?.walletAddress) {
    return (
      <Container className={className} onClick={handleOnBindWalletClick}>
        <IconContainer>
          <Icon src={RoninSVG} />
        </IconContainer>
        <Text>{t('account_menu.bind_wallet')}</Text>
      </Container>
    )
  }

  return (
    <Container className={className} onClick={handleOnWalletClick}>
      <Icon src={WalletSVG} />
      <WalletAddress>{account.walletAddress}</WalletAddress>
    </Container>
  )
}

type Props = {
  className?: string
}

export default WalletBlueButton
