import {
  deserializeData,
  MayNull,
  SsoProviderType,
  SsoRedirect,
  ssoRedirectSchema
} from '@apeiron/library'
import { useLazyQuery } from '@apollo/client'
import { ROUTE } from '@src/constants/route'
import graphql from '@src/graphql/query/getOAuthLoginLink'
import { useCallback, useState } from 'react'

const useGetOAuthLink = (): Hook => {
  const [query] = useLazyQuery(graphql)

  const [data, setData] = useState<Response>()

  const fetch = useCallback(
    async (type: SsoProviderType) => {
      const response = await query({
        variables: {
          oauthProviderName: type,
          redirectURI: `${location.origin}${ROUTE.CALLBACK_SSO}`
        }
      })

      const result = deserializeData<SsoRedirect>(response, ssoRedirectSchema)

      setData(result)

      return result
    },
    [query]
  )

  return {
    fetch,
    data
  }
}

type Response = MayNull<SsoRedirect>

type Hook = {
  fetch: (type: SsoProviderType) => Promise<Response>
  data: Response
}

export default useGetOAuthLink
