import { conjunctItemSchema } from '@src/deserialize/yup/conjunct'
import {
  FrontendAposteSlot,
  FrontendAttachConfig,
  FrontendBornConfig,
  FrontendBountyConfig,
  FrontendBreedConfig,
  FrontendConfig,
  FrontendDiscountConfig,
  FrontendMaintenance,
  FrontendManagePlanetApostleConfig,
  FrontendOrbitRentalConfig,
  FrontendPlanetConfig,
  FrontendRewardCenterConfig,
  FrontendRoninMarketplaceConifg
} from '@src/types/config'
import * as yup from 'yup'

const frontendApostleSlotSchema: yup.SchemaOf<FrontendAposteSlot> = yup
  .object({
    age: yup.number().default(0),
    slot: yup.number().default(0)
  })
  .from('slot_count', 'slot')
  .noUnknown(true)

export const frontendAttachConfigSchema: yup.SchemaOf<FrontendAttachConfig> =
  yup
    .object({
      cooldown: yup.number().default(0)
    })
    .noUnknown(true)

export const frontendBreedConfigSchema: yup.SchemaOf<FrontendBreedConfig> = yup
  .object({
    cooldown: yup.number().default(0),
    max: yup.number().default(5),
    breedCost: yup.array().of(yup.array().of(conjunctItemSchema)).default([]),
    breedCostTable: yup.object<any>()
  })
  .from('max_count', 'max')
  .from('breed_cost', 'breedCost')
  .from('breed_cost_table', 'breedCostTable')
  .noUnknown(true)

export const frontendPlanetConfigSchema: yup.SchemaOf<FrontendPlanetConfig> =
  yup
    .object({
      apostleSlotTable: yup.array().of(frontendApostleSlotSchema).default([]),
      maxActiveSkill: yup.number().default(3),
      maxAge: yup.number().default(0)
    })
    .from('age_to_relic_apostle_slot', 'apostleSlotTable')
    .from('max_active_skill', 'maxActiveSkill')
    .from('max_age', 'maxAge')
    .noUnknown(true)

export const frontendManagePlanetApostleConfigSchema: yup.SchemaOf<FrontendManagePlanetApostleConfig> =
  yup
    .object({
      maxManageCount: yup.number().default(0),
      maxSelectedPlanet: yup.number().default(0)
    })
    .from('max_manage_count', 'maxManageCount')
    .from('max_selected_planet', 'maxSelectedPlanet')
    .noUnknown(true)

export const frontendBornConfigSchema: yup.SchemaOf<FrontendBornConfig> = yup
  .object({
    cooldown: yup.number().default(0)
  })
  .from('born_cooldown_in_second', 'cooldown')
  .noUnknown(true)

export const frontendMaintenanceSchema: yup.SchemaOf<FrontendMaintenance> = yup
  .object({
    endTime: yup.string().required().default(''),
    isMaintenance: yup.boolean().default(false),
    paths: yup.array().of(yup.string().required()).default([''])
  })
  .camelCase()
  .noUnknown(true)

export const frontendOrbitRentalConfigSchema: yup.SchemaOf<FrontendOrbitRentalConfig> =
  yup
    .object({
      cooldown: yup.number().default(0),
      cooldownPlanet: yup.number().default(0),
      fee: yup.number().default(0),
      maxAttachEachTime: yup.number().default(0),
      maxDuration: yup.number().default(0),
      minDuration: yup.number().default(0)
    })
    .from('attach_planet_to_star_max', 'maxAttachEachTime')
    .from('planet_attach_cooldown', 'cooldownPlanet')
    .from('platform_fee', 'fee')
    .from('rental_cool_down_in_hr', 'cooldown')
    .from('rental_duration_max_in_day', 'maxDuration')
    .from('rental_duration_min_in_day', 'minDuration')

export const frontendRewardCenterConfigSchema: yup.SchemaOf<FrontendRewardCenterConfig> =
  yup
    .object({
      claimableIds: yup.array().of(yup.number()).default([])
    })
    .from('claimable_ids', 'claimableIds')

export const frontendRoninMarketplaceConfigSchema: yup.SchemaOf<FrontendRoninMarketplaceConifg> =
  yup
    .object()
    .shape({
      createOrderTypedDataErc1155: yup.object().required(),
      createOrderTypedDataErc721: yup.object().required()
    })
    .transform(value => {
      return {
        createOrderTypedDataErc1155:
          JSON.parse(value.create_order_typed_data_erc1155) || {},
        createOrderTypedDataErc721:
          JSON.parse(value.create_order_typed_data_erc721) || {}
      }
    })
    .default({
      createOrderTypedDataErc1155: {},
      createOrderTypedDataErc721: {}
    })

export const discountConfigSchema: yup.SchemaOf<FrontendDiscountConfig> = yup
  .object()
  .shape({
    planetBreed: yup.object()
  })
  .from('planet_breed', 'planetBreed')
  .default({
    planetBreed: {}
  })

export const bountyConfigSchema: yup.SchemaOf<FrontendBountyConfig> = yup
  .object()
  .shape({
    apostle: yup.object()
  })
  .default({
    apostle: {
      supportedCurrencies: []
    }
  })

export const frontendConfigSchema: yup.SchemaOf<FrontendConfig> = yup
  .object({
    apostleMintConfig: yup.array().of(yup.object()).default([]),
    bounty: bountyConfigSchema,
    attach: frontendAttachConfigSchema,
    breed: frontendBreedConfigSchema,
    managePlanetApostle: frontendManagePlanetApostleConfigSchema,
    planet: frontendPlanetConfigSchema,
    maintenance: yup.array().of(frontendMaintenanceSchema).default([]),
    orbitRental: frontendOrbitRentalConfigSchema,
    rewardCenter: frontendRewardCenterConfigSchema,
    roninMarketplace: frontendRoninMarketplaceConfigSchema,
    discount: discountConfigSchema
  })
  .from('frontendConfig.attach', 'attach')
  .from('frontendConfig.breed', 'breed')
  .from('frontendConfig.planet', 'planet')
  .from('frontendConfig.manage_planet_apostle', 'managePlanetApostle')
  .from('frontendConfig.maintenance', 'maintenance')
  .from('frontendConfig.rental_orbital_track', 'orbitRental')
  .from('frontendConfig.reward_center', 'rewardCenter')
  .from('frontendConfig.ronin_marketplace', 'roninMarketplace')
  .from('frontendConfig.discount', 'discount')
  .noUnknown(true)
