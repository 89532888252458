import { AssetType, useApeironPathMaker, useFetchJson } from '@apeiron/library'
import CONFIG from '@src/config'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import { planetFilterDataSchema } from '@src/deserialize/yup/planetFilter'
import { PlanetFilterData } from '@src/types/planetFilter'
import { useCallback } from 'react'

const { JSON_FILE_NAME } = CONFIG

const useFetchPlanetFilterJson = (): Hook => {
  const { makePath } = useApeironPathMaker()

  const { preloadValue } = usePreloadContext()

  const { fetchJson: doFetch } = useFetchJson({
    url: makePath(AssetType.Json, JSON_FILE_NAME.PLANET_FILTERS)
  })

  const fetchJson = useCallback(async (): Promise<PlanetFilterData> => {
    const response = await doFetch()

    return planetFilterDataSchema.cast(response) as PlanetFilterData
  }, [doFetch])

  const fetchWithPreload = useCallback(() => {
    return preloadValue(PRELOAD_KEY.PLANET_FILTERS, fetchJson)
  }, [preloadValue, fetchJson])

  return {
    fetchJson: fetchWithPreload
  }
}

type Hook = {
  fetchJson: () => Promise<PlanetFilterData>
}

export default useFetchPlanetFilterJson
