import {
  SsoProviderType,
  SsoPurpose,
  useAccountContext
} from '@apeiron/library'
import { ROUTE } from '@src/constants/route'
import useGetOAuthLink from '@src/hooks/graphql/useGetOAuthLink'
import * as R from 'ramda'
import { useCallback } from 'react'

const useSsoAuth = (): Hook => {
  const { fetch } = useGetOAuthLink()

  const { storeSsoState } = useAccountContext()

  const redirect = useCallback(
    async (type: SsoProviderType, purpose: SsoPurpose) => {
      const ssoRedirect = await fetch(type)

      if (R.isNotNil(ssoRedirect)) {
        storeSsoState({
          state: ssoRedirect?.state,
          appType: type,
          redirectURI: `${location.origin}${ROUTE.CALLBACK_SSO}`,
          currentUrl: location.pathname + location.search,
          purpose
        })

        window.location.assign(ssoRedirect.url)
      }
    },
    [fetch, storeSsoState]
  )

  const link = useCallback(
    async (type: SsoProviderType) => {
      await redirect(type, SsoPurpose.LinkApp)
    },
    [redirect]
  )

  const login = useCallback(
    async (type: SsoProviderType) => {
      await redirect(type, SsoPurpose.Login)
    },
    [redirect]
  )

  return {
    link,
    login
  }
}

type Hook = {
  link: (type: SsoProviderType) => Promise<void>
  login: (type: SsoProviderType) => Promise<void>
}

export default useSsoAuth
