import { createStrictContext } from '@apeiron/library'
import { Dispatch, ReactNode, SetStateAction, useState } from 'react'

type ContextType = {
  showGrid: boolean
  setShowGrid: Dispatch<SetStateAction<boolean>>
}

const [ContextProvider, useDataGridContext] = createStrictContext<ContextType>(
  'DataGrid',
  { showGrid: true, setShowGrid: () => {} }
)

export { useDataGridContext }

export const DataGridProvider = (props: Props) => {
  const { children } = props

  const [showGrid, setShowGrid] = useState(true)

  return (
    <ContextProvider value={{ showGrid, setShowGrid }}>
      {children}
    </ContextProvider>
  )
}

export type Props = {
  children: ReactNode
}
