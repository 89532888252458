import { MuiLoading } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

const Container = styled(Box)`
  width: 100%;
  text-align: center;
  padding: 30px 0px;
`

const LoadingNow: FC<Props> = (props: Props) => {
  const { className } = props

  return (
    <Container className={className}>
      <MuiLoading loading />
    </Container>
  )
}

type Props = {
  className?: string
}

export default LoadingNow
