import { gql } from '@apollo/client'

export default gql`
  query GetOAuthLoginLink($oauthProviderName: String!, $redirectURI: String!) {
    getOAuthLoginLink(
      input: {
        oauthProviderName: $oauthProviderName
        redirectURI: $redirectURI
      }
    ) {
      loginURL
      state
    }
  }
`
