import { NextImage } from '@apeiron/library'
import { Box, Button, styled, Typography } from '@mui/material'
import LeftArrowSVG from '@public/icons/icon-left-arrow.svg'
import RightArrowSVG from '@public/icons/icon-right-arrow.svg'
import { TextInput } from '@src/components/share/generic/TextInput'
import * as R from 'ramda'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const PageButton = styled(Button)`
  width: 65px;
  height: 35px;
  border-radius: 3px;
  background-color: #252728;
  :hover {
    background-color: #535556;
  }
  :disabled {
    background-color: #151615;
  }
`

const PrevPageButton = styled(PageButton)`
  margin-right: 26px;
`

const NextPageButton = styled(PageButton)`
  margin-left: 26px;
`

const LeftArrowIcon = styled(NextImage)<{ disabled: boolean }>`
  height: 100%;
  width: 100%;
  ${props => (props.disabled ? 'filter: opacity(0.3)' : 'filter: opacity(1)')};
`

const RightArrowIcon = styled(NextImage)<{ disabled: boolean }>`
  height: 100%;
  width: 100%;
  ${props => (props.disabled ? 'filter: opacity(0.3)' : 'filter: opacity(1)')};
`

const PageContainer = styled(Box)`
  display: flex;
  width: 65px;
  height: 35px;
  background-color: #151615;
  border-radius: 3px;
`

const PageInput = styled(TextInput)`
  background-color: #151615;
  color: #ffffff;

  & .MuiOutlinedInput-input {
    font-size: 16px;
    margin-top: 2px;
    text-align: center;
  }
`

const Text = styled(Typography)`
  color: #ffffff;
  font-size: 16px;
`

const PageText = styled(Text)`
  margin-right: 22px;
`

const TotalPageText = styled(Text)`
  margin-left: 18px;
  white-space: nowrap;
`

const validatePage = (page: number, totalPage?: number): boolean => {
  return (
    Number.isInteger(page) &&
    page > 0 &&
    (totalPage != null ? page <= totalPage : true)
  )
}

export const Pagination: FC<Props> = (props: Props) => {
  const {
    className,
    nextDisabled = false,
    prevDisabled = false,
    totalPage,
    value,
    onNext,
    onPrev,
    onSubmit
  } = props

  const { t } = useTranslation()

  const [textValue, setTextValue] = useState<string>(value.toString())

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setTextValue(e.target.value)
    },
    []
  )

  const handleSubmit = useCallback(() => {
    const numValue = Math.round(Number(textValue))

    if (isNaN(numValue) || !validatePage(numValue, totalPage)) {
      // invalid number, let's forget the edit
      setTextValue(value.toString())

      return
    }

    if (onSubmit && numValue !== value) {
      onSubmit(numValue)
    }
  }, [onSubmit, textValue, totalPage, value])

  const onInputBlur: React.FocusEventHandler<HTMLInputElement> =
    useCallback(() => {
      handleSubmit()
    }, [handleSubmit])

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      // Blur the active element (text fields) to apply changes to filter
      const focusedElement = e.currentTarget.ownerDocument.activeElement

      if (focusedElement instanceof HTMLElement) {
        focusedElement.blur()
      }

      handleSubmit()
    },
    [handleSubmit]
  )

  useEffect(() => {
    setTextValue(value.toString())
  }, [value])

  return (
    <Container className={className}>
      <PrevPageButton
        className='Custom-Page-Button'
        disabled={prevDisabled}
        onClick={onPrev}
      >
        <LeftArrowIcon src={LeftArrowSVG} disabled={prevDisabled} />
      </PrevPageButton>
      <PageText>{t('pagination.page')}</PageText>
      <PageContainer>
        <form onSubmit={onFormSubmit}>
          <PageInput
            value={textValue}
            onChange={onInputChange}
            onBlur={onInputBlur}
            type='text'
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              'aria-label': 'page number input'
            }}
          />
        </form>
      </PageContainer>
      {!R.isNil(totalPage) && totalPage > 0 ? (
        <TotalPageText>
          {t('pagination.total_page_number', { totalPageNumber: totalPage })}
        </TotalPageText>
      ) : (
        <></>
      )}
      <NextPageButton
        className='Custom-Page-Button'
        disabled={nextDisabled}
        onClick={onNext}
      >
        <RightArrowIcon src={RightArrowSVG} disabled={nextDisabled} />
      </NextPageButton>
    </Container>
  )
}

type Props = {
  className?: string
  nextDisabled?: boolean
  prevDisabled?: boolean
  totalPage?: number
  value: number
  onPrev?: () => void
  onNext?: () => void
  onSubmit?: (page: number) => void
}
