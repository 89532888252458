import { useWeb3, WalletExtensionRdns } from '@apeiron/library'
import { openMobileWallet, toDeepLink } from '@roninnetwork/wallet-sdk'
import { useEffect, useState } from 'react'

const useWalletConnect = () => {
  const { connectors } = useWeb3()

  const [roninQRCode, setRoninQRCode] = useState<string | undefined>()

  const walletConnectConnector = connectors.find(
    connector => connector.id === WalletExtensionRdns.WalletConnect
  )

  useEffect(() => {
    if (!walletConnectConnector) {
      return
    }

    const eventHandler = (message: any) => {
      if (message.type === 'display_uri') {
        setRoninQRCode(message.data as string)
      }
    }

    walletConnectConnector?.emitter.on('message', eventHandler)

    return () => {
      walletConnectConnector?.emitter.off('message', eventHandler)
    }
  }, [walletConnectConnector])

  const getDeepLink = () => {
    if (!roninQRCode) {
      return ''
    }

    const deepLink = toDeepLink(roninQRCode)

    return deepLink
  }

  return {
    roninQRCode,
    openRoninMobileWallet: openMobileWallet,
    getDeepLink
  }
}

export default useWalletConnect
