import { AssetType, useApeironPathMaker, useFetchJson } from '@apeiron/library'
import CONFIG from '@src/config'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import { constellationInFilterSchema } from '@src/deserialize/yup/starFilter'
import { ConstellationInFilter } from '@src/types/star'
import * as R from 'ramda'
import { useCallback } from 'react'

const useFetchConstellationJson = (): Hook => {
  const { makePath } = useApeironPathMaker()

  const { preloadValue } = usePreloadContext()

  const { fetchJson: doFetch } = useFetchJson({
    url: makePath(AssetType.Json, CONFIG.JSON_FILE_NAME.CONSTELLATIONS)
  })

  const fetchJson = useCallback(async (): Promise<ConstellationInFilter[]> => {
    const response = ((await doFetch()) as Object) || {}

    const constellations = R.map(data => {
      return constellationInFilterSchema.cast(data, {
        context: {
          data,
          domain: CONFIG.DOMAIN_URL.ASSET
        }
      }) as ConstellationInFilter
    }, R.values(response))

    return constellations
  }, [doFetch])

  const fetchWithPreload = useCallback(() => {
    return preloadValue(PRELOAD_KEY.CONSTELLATIONS_DATA, fetchJson)
  }, [preloadValue, fetchJson])

  return {
    fetchJson: fetchWithPreload
  }
}

type Hook = {
  fetchJson: () => Promise<ConstellationInFilter[]>
}

export default useFetchConstellationJson
