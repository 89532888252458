import {
  AssetType,
  SsoProvider,
  ssoProviderSchema,
  useApeironPathMaker,
  useFetchJson
} from '@apeiron/library'
import CONFIG from '@src/config'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

const { JSON_FILE_NAME } = CONFIG

const useFetchSsoJson = (): Hook => {
  const { makePath } = useApeironPathMaker()

  const { preloadValue } = usePreloadContext()

  const { fetchJson: doFetch } = useFetchJson({
    url: makePath(AssetType.Json, JSON_FILE_NAME.SSO)
  })

  const [data, setData] = useState<SsoProvider[]>([])

  const fetchJson = useCallback(async (): Promise<SsoProvider[]> => {
    const response =
      ((await preloadValue(PRELOAD_KEY.SSO_PROVIDERS, doFetch)) as any[]) || []

    const platforms = R.map((item: any) => {
      return ssoProviderSchema.cast(item, {
        context: { data: item }
      }) as SsoProvider
    }, response)

    setData(platforms)

    return platforms
  }, [doFetch, preloadValue])

  return {
    data,
    fetchJson
  }
}

type Hook = {
  data: SsoProvider[]
  fetchJson: () => Promise<SsoProvider[]>
}

export default useFetchSsoJson
