import { createStrictContext } from '@apeiron/library'
import { PlanetClassType } from '@src/constants/planet'
import useFetchPlanetFilterJson from '@src/hooks/json/useFetchPlanetFilterJson'
import { PlanetClass } from '@src/types/planet'
import * as R from 'ramda'
import { FC, ReactChild, useCallback, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

const [ContextProvider, usePlanetClassContext] =
  createStrictContext<PlanetClasssContextValue>('PlanetClass')

export { usePlanetClassContext }

export const PlanetClassProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const { fetchJson: fetchFilterJson } = useFetchPlanetFilterJson()

  const [planetClasses, setPlanetClasses] = useState<PlanetClass[]>([])

  const extractPlanetClass = useCallback(
    (type: PlanetClassType): PlanetClass | undefined => {
      return R.find(
        (planetClass: PlanetClass) => planetClass.type === type,
        planetClasses
      )
    },
    [planetClasses]
  )

  useAsyncEffect(async (isActive: () => boolean) => {
    const filterSetting = await fetchFilterJson()

    if (!isActive()) {
      return
    }

    const classes = R.propOr([], 'planetFilterClass', filterSetting) as any[]

    setPlanetClasses(classes)
  }, [])

  return (
    <ContextProvider value={{ planetClasses, extractPlanetClass }}>
      {children}
    </ContextProvider>
  )
}

export type PlanetClasssContextValue = {
  planetClasses: PlanetClass[]
  extractPlanetClass: (type: PlanetClassType) => PlanetClass | undefined
}

export type Props = {
  children: ReactChild | ReactChild[]
}
