import {
  ConfirmButton,
  INFO_POPUP_TYPE,
  InfoPopup,
  MuiTextfield,
  PopupTitle,
  useAccountContext,
  useMessageContext
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import useMktErrorHandling from '@src/hooks/common/errorHandling/useMktErrorHandling'
import useUpdateAccountName from '@src/hooks/graphql/useUpdateAccountName'
import { padNumber } from '@src/util/tools/string'
import * as R from 'ramda'
import { FC, useCallback } from 'react'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ACCOUNT_NAME_MAX_LENGTH = 20

const ACCOUNT_TAG_LENGTH = 4

const ACCOUNT_TAG_MIN = 1

const ACCOUNT_TAG_MAX = 9999

const StyledInfoPopup = styled(InfoPopup)`
  max-width: 500px;
  width: 95%;
  height: unset;
`

const Form = styled('form')`
  display: flex;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  ${props => props.theme.breakpoints.down('res1024')} {
    padding-right: 10px;
    padding-left: 10px;
  }
`

const StyledPopupTitle = styled(PopupTitle)`
  width: 90%;
  margin-bottom: 15px;
`

const Container = styled(Box)`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`

const InputName = styled(MuiTextfield)`
  flex: 2;
`

const InputTag = styled(MuiTextfield)`
  flex: 1;
`

const ChangeNamePopup: FC<Props> = (props: Props) => {
  const { open, onClose } = props

  const { t } = useTranslation()

  const { mutate: updateAccountName } = useUpdateAccountName()

  const { account } = useAccountContext()

  const { showGeneralError, setInfo } = useMessageContext()

  const { showErrorPopup } = useMktErrorHandling()

  const { control, handleSubmit, reset } = useForm({ mode: 'onChange' })

  const handleOnClose = useCallback(() => {
    if (onClose) {
      onClose()
    }

    reset()
  }, [onClose, reset])

  const doSubmit = useCallback(
    async (data: Record<string, any>) => {
      const { name, tag } = data

      try {
        const success = await updateAccountName({
          name,
          tag
        })

        if (success) {
          setInfo({
            message: t('account_setting.name_updated'),
            confirm: t('common.popup.ok'),
            crossButton: true
          })

          if (onClose) {
            onClose()
          }
        } else {
          showGeneralError(t('error.graphql.fallback.update_info'))
        }
      } catch (error) {
        showErrorPopup(error)
      }
    },
    [onClose, setInfo, showErrorPopup, showGeneralError, t, updateAccountName]
  )

  return (
    <StyledInfoPopup
      open={open}
      type={INFO_POPUP_TYPE.BLACK}
      onClose={handleOnClose}
    >
      <Form onSubmit={handleSubmit(doSubmit)}>
        <StyledPopupTitle
          title={t('popup.change_name.title')}
          subtitle={t('popup.change_name.subtitle')}
        />
        <Container>
          <Controller
            name='name'
            control={control}
            defaultValue={account.name}
            rules={{
              required: true,
              minLength: 1,
              maxLength: ACCOUNT_NAME_MAX_LENGTH
            }}
            render={(props: {
              field: ControllerRenderProps<any, 'name'>
              fieldState: ControllerFieldState
            }) => {
              const {
                field,
                fieldState: { error }
              } = props

              return (
                <InputName
                  {...field}
                  label={t('account_setting.change_name_label')}
                  error={!R.isNil(error)}
                  helperText={
                    error
                      ? t('account_setting.change_name_error', {
                          length: ACCOUNT_NAME_MAX_LENGTH
                        })
                      : ''
                  }
                />
              )
            }}
          />
          <Controller
            name='tag'
            control={control}
            defaultValue={padNumber(account.tag, ACCOUNT_TAG_LENGTH)}
            rules={{
              required: true,
              min: ACCOUNT_TAG_MIN,
              max: ACCOUNT_TAG_MAX
            }}
            render={(props: {
              field: ControllerRenderProps<any, 'tag'>
              fieldState: ControllerFieldState
            }) => {
              const {
                field,
                fieldState: { error }
              } = props

              return (
                <InputTag
                  {...field}
                  label={t('account_setting.change_tag_label')}
                  type='tel'
                  error={!R.isNil(error)}
                  helperText={
                    error
                      ? t('account_setting.change_tag_error', {
                          min: ACCOUNT_TAG_MIN,
                          max: ACCOUNT_TAG_MAX
                        })
                      : ''
                  }
                  inputProps={{
                    startAdornment: '#',
                    maxLength: ACCOUNT_TAG_LENGTH
                  }}
                />
              )
            }}
          />
        </Container>
        <ConfirmButton />
      </Form>
    </StyledInfoPopup>
  )
}

type Props = {
  open: boolean
  onClose?: () => void
}

export default ChangeNamePopup
