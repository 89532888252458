import { ApeironLinkButton, IconText } from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import LootRushPNG from '@public/icons/icon-lootrush.png'
import MarketplacePNG from '@public/icons/icon-marketplace.png'
import EmptyStateIcon from '@src/components/share/icon/EmptyStateIcon'
import * as RA from 'ramda-adjunct'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px;
  ${props => props.theme.breakpoints.up('res480')} {
    font-size: 24px;
    line-height: 30px;
  }
  ${props => props.theme.breakpoints.down('res480')} {
    font-size: 26px;
    line-height: 30px;
  }
`

const StyledEmptyStateIcon = styled(EmptyStateIcon)`
  aspect-ratio: 1;
  ${props => props.theme.breakpoints.up('res480')} {
    width: 120px;
    margin-bottom: 40px;
  }
  ${props => props.theme.breakpoints.down('res480')} {
    width: 100px;
    margin-bottom: 16px;
  }
`

const Description = styled(Typography)`
  font-size: 22px;
  font-weight: 300;
  color: unset;
`

const ButtonContainer = styled(Box)`
  display: flex;
  column-gap: 40px;
  row-gap: 30px;
  margin-top: 40px;
  flex-wrap: wrap;
  justify-content: center;
`

const RentButton = styled(ApeironLinkButton)`
  background-color: #321d5e;
  border-radius: 5px;
  border: 2px solid #5e35b2;
  padding: 10px 45px;
  min-width: 270px;
`

const BuyButton = styled(RentButton)`
  border: unset;
  background-color: white;
`

const RentText = styled(IconText)`
  gap: 20px;
  .Custom-Icon {
    aspect-ratio: 130 / 38;
    width: 100px;
  }
  .Custom-Text {
    font-size: 18px;
    font-weight: 500;
  }
`

const BuyText = styled(IconText)`
  gap: 6px;
  .Custom-Icon {
    filter: brightness(0);
    aspect-ratio: 1;
    width: 26px;
  }
  .Custom-Text {
    color: black;
    font-size: 18px;
    font-weight: 500;
  }
`

export const SearchEmptyState: FC<SearchEmptyStateProps> = props => {
  const { buyURL, className, description, rentURL } = props

  const { t } = useTranslation()

  const showRecommend =
    RA.isNotNilOrEmpty(buyURL) || RA.isNotNilOrEmpty(rentURL)

  return (
    <Container className={className}>
      <StyledEmptyStateIcon className='Custom-Search-Empty-Icon' />
      <Description className='Custom-Search-Empty-Description'>
        {description || t('common.empty_list')}
      </Description>
      {showRecommend && (
        <ButtonContainer>
          {RA.isNotNilOrEmpty(rentURL) && (
            <RentButton
              aria-label='Rent in Lootrush'
              url={rentURL}
              target='_blank'
            >
              <RentText
                text={t('empty_search.rent')}
                endSrc={LootRushPNG.src}
              />
            </RentButton>
          )}
          {RA.isNotNilOrEmpty(buyURL) && (
            <BuyButton
              aria-label='Buy in Emporium'
              url={buyURL}
              target='_blank'
            >
              <BuyText
                text={t('empty_search.buy')}
                startSrc={MarketplacePNG.src}
              />
            </BuyButton>
          )}
        </ButtonContainer>
      )}
    </Container>
  )
}

export type SearchEmptyStateProps = {
  buyURL?: string
  className?: string
  description?: string
  rentURL?: string
}
