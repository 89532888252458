import * as R from 'ramda'

export const maskString = (
  input: string,
  mask = '****',
  offset = 5
): string => {
  if (R.isNil(input) || R.isEmpty(input)) {
    return ''
  }

  const start = R.slice(0, offset, input)

  const end = R.slice(input.length - offset, input.length, input)

  return `${start}${mask}${end}`
}

export const padNumber = (
  num: number | string | undefined | null,
  size: number
): string => {
  let s = String(num || 0)

  while (s.length < (size || 2)) {
    s = '0' + s
  }

  return s
}

export const commaNumber = (input: number) => input.toLocaleString()

export const checkIsNumber = (input: string) => {
  return /^-{0,1}\d*\.{0,1}\d+$/.test(input)
}

const checkIsBoolean = (input: string) => {
  return input === 'true' || input === 'false'
}

// e.g. { a: '1', b: 'b', c: 'true' } >> { a: 1, b: 'b', c: true }
export const makeTypeObject = (data: any): any => {
  if (!R.is(Array, data) && !R.is(Object, data)) {
    if (R.is(String, data)) {
      if (checkIsNumber(data)) {
        return Number(data)
      } else if (checkIsBoolean(data)) {
        return Boolean(data)
      }
    }

    return data
  }

  const convertedData = R.map(subData => makeTypeObject(subData), data)

  return convertedData
}

export const recursiveToString = (input: any): any => {
  if (R.is(String, input) || R.is(Number, input)) {
    return String(input)
  }

  if (R.is(Object, input) || R.is(Array, input)) {
    return R.map(value => recursiveToString(value), input)
  }

  return null
}

export const decToHex = (input: string, prefix: string = ''): string => {
  return prefix + BigInt(input).toString(16)
}
