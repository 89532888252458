// ? checklist, any graphql calling without access token, will skip and return error the call
export const OPERATIONS_WITH_AUTH = [
  // marketplace
  'BindAccountEmailAndPassword',
  'BookmarkNFT',
  'BuyDailyCheckInShopItem',
  'ChangeGuild',
  'CreateFavoriteFilter',
  'GetAccountExpeditionReward',
  'GetAccountExpeditionRewardStatus',
  'GetAccountInvitees',
  'GetAccountVaporBalance',
  'GetBattleDemoRewards',
  'GetConnectedApps',
  'GetFavoriteFilters',
  'GetMyApostleActivities',
  'GetMyApostles',
  'GetMyGodiverses',
  'GetMyGodiversesActivities',
  'GetMyGuild',
  'GetMyNotifications',
  'GetMyOrbitalTracks',
  'GetMyPlanetActivities',
  'GetMyPlanets',
  'GetMyReservedSlot',
  'GetMyStars',
  'GetRewardBalances',
  'GetRewardClaimHistory',
  'GetVaporDailyEarn',
  'GetVestingHistories',
  'isBookmarked',
  'RemoveFavoriteFilter',
  'SaveInviteSetting',
  'SignClaimableRewards',
  'SignSeasonMint',
  'UpdateAccountEmail',
  'UpdateAccountName',
  'UpdateAccountNotification',
  'UpdateAccountPassword',
  'UpdateApostleName',
  'UpdatePlanetName',
  'VerifyAccount',
  'VerifyOrbitalTrack',

  // share-lib
  'BindFingerprint',
  'BindWalletAddress',
  'GetAccountDetail',
  'GetMyHostedWallet',
  'Logout',
  'SendOtpCode'
]
