import {
  ChainNetworkType,
  ProviderConfig,
  ShareLibProviders
} from '@apeiron/library'
import { FractalProvider } from '@fractalwagmi/react-sdk'
import ApeironProviderSwitcher from '@src/components/share/apeiron/ApeironProviderSwitcher'
import ENV_CONFIG from '@src/config'
import { config } from '@src/config/wagmi'
import { AUTO_BELIEVE_ROUTES } from '@src/constants/adeptus'
import buildId from '@src/constants/buildId'
import { OPERATIONS_WITH_AUTH } from '@src/constants/graphql'
import { RoninNameServiceProvider } from '@src/contexts/contract/RoninNameServiceContext'
import { ElementProvider } from '@src/contexts/planet/ElementContext'
import { PlanetClassProvider } from '@src/contexts/planet/PlanetClassContext'
import { FrontendConfigProvider } from '@src/contexts/share/FrontendConfigContext'
import { FullScreenBlockingProvider } from '@src/contexts/share/FullScreenBlockingContext'
import { HistoryProvider } from '@src/contexts/share/HistoryContext'
import { NotificationProvider } from '@src/contexts/share/NotificationContext'
import { NowProvider } from '@src/contexts/share/NowContext'
import { PreloadProvider } from '@src/contexts/share/PreloadContext'
import { SecondaryNavBarProvider } from '@src/contexts/share/SecondaryNavBarContext'
import { ConstellationsProvider } from '@src/contexts/star/ConstellationsContext'
import { CurrencyProvider } from '@src/contexts/wallet/CurrencyContext'
import { RoninWalletSnackbarProvider } from '@src/contexts/wallet/RoninWalletSnackbarContext'
import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo } from 'react'

// global providers
const Providers: FC<Props> = ({ children }) => {
  const router = useRouter()

  const providerConfig: ProviderConfig = useMemo(() => {
    return {
      adpetus: {
        autoBelievePaths: AUTO_BELIEVE_ROUTES
      },
      api: {
        authOperations: OPERATIONS_WITH_AUTH,
        domainCommon: ENV_CONFIG.API_URL.MARKETPLACE,
        domainLogin: ENV_CONFIG.API_URL.MARKETPLACE
      },
      asset: {
        domain: ENV_CONFIG.DOMAIN_URL.ASSET
      },
      chain: {
        preferredNetwork: ChainNetworkType.Ronin
      },
      fingerPrint: {
        key: ENV_CONFIG.ID.FINGERPRINT
      },
      geeTest: {
        id: ENV_CONFIG.ID.GEETEST
      },
      json: {
        app: ENV_CONFIG.VARIABLES.NAME,
        id: buildId,
        domain: ENV_CONFIG.DOMAIN_URL.JSON
      },
      next: {
        router
      },
      walletConnect: {
        id: ENV_CONFIG.ID.WALLETCONNECT
      },
      wagmiConfig: config
    }
  }, [router])

  const fractalId = ENV_CONFIG.ID.FRACTAL

  return (
    <ShareLibProviders providerConfig={providerConfig}>
      <FractalProvider clientId={fractalId}>
        <NowProvider>
          <CurrencyProvider>
            <FullScreenBlockingProvider>
              <NotificationProvider>
                <PreloadProvider>
                  <FrontendConfigProvider>
                    <ElementProvider>
                      <PlanetClassProvider>
                        <ConstellationsProvider>
                          <SecondaryNavBarProvider>
                            <HistoryProvider>
                              <ApeironProviderSwitcher>
                                <RoninWalletSnackbarProvider>
                                  <RoninNameServiceProvider>
                                    {children}
                                  </RoninNameServiceProvider>
                                </RoninWalletSnackbarProvider>
                              </ApeironProviderSwitcher>
                            </HistoryProvider>
                          </SecondaryNavBarProvider>
                        </ConstellationsProvider>
                      </PlanetClassProvider>
                    </ElementProvider>
                  </FrontendConfigProvider>
                </PreloadProvider>
              </NotificationProvider>
            </FullScreenBlockingProvider>
          </CurrencyProvider>
        </NowProvider>
      </FractalProvider>
    </ShareLibProviders>
  )
}

type Props = {
  children: ReactNode
}

export default Providers
