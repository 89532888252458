import Decimal from 'decimal.js'
import fromExponential from 'from-exponential'
import * as R from 'ramda'

const convertExponentialPrice = (
  rawInput: string,
  maxLength: number,
  exponentialDecimal: number
) => {
  const invalid = isNaN(Number(rawInput)) || R.startsWith('-', rawInput)

  if (invalid) {
    // block non-number string and negative number
    return '0'
  }

  const input = fromExponential(rawInput) as string

  const [integer = ''] = input.split('.')

  const startWithZero = integer === '0'

  if (!startWithZero) {
    // e.g. 1.15
    if (integer.length <= maxLength) {
      // e.g. 100.15
      return new Decimal(input).toFixed(
        Math.min(maxLength - integer.length, exponentialDecimal)
      )
    } else if (integer.length > maxLength) {
      // e.g. 1000000000.15
      return new Decimal(input).toExponential(exponentialDecimal)
    }
  } else {
    // e.g. 0.15
    const eNontationNumber = new Decimal(input).toExponential(
      exponentialDecimal
    )

    const [beforeE, afterE] = eNontationNumber.split('e')

    const replacedTrailingZero = beforeE.replace(/\.0+$/, '')

    return `${replacedTrailingZero}e${afterE}`
  }

  return input
}

const isDecimalOverflow = (input: string, maxDecimal: number) => {
  const [, decimal = ''] = input.split('.')

  return decimal.length > maxDecimal
}

export type FormatPriceOptions = {
  // when Number(x) < minNumber, display x in e-notation
  minNumberWithoutENotation?: number
  // when Number(x) > maxNumber, display x in e-notation
  maxNumberWithoutENotation?: number
  // when String(x).length > maxLength, truncate x to maxLength
  maxLength?: number
  // max decimal places
  maxDecimal?: number
  allowENotation?: boolean
  allowedLargeNumberAbbr?: boolean
  eNotationDecimal?: number
  // show integer only when x > 1
  intOnlyOverOne?: boolean
  largeNumberAbbrMaxDecimal?: number
  priorities?: ('maxLength' | 'maxDecimal')[]
}

const LARGE_NUMBER_ABBRS = ['', 'K', 'M', 'B', 'T']

const removeExponential = (number: string) => {
  const sign = +number < 0 ? '-' : ''

  const toStr = number.toString()

  if (!/e/i.test(toStr)) {
    return number
  }

  const [lead, decimal, pow] = number
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./)

  return +pow < 0
    ? sign +
        '0.' +
        '0'.repeat(Math.max(Math.abs(parseFloat(pow)) - 1 || 0, 0)) +
        lead +
        decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow))
}

const getLargeNumberAbbr = (num: number) => {
  let integer = num.toFixed(0)

  if (integer.includes('e')) {
    integer = removeExponential(integer)
  }

  const index = Math.floor((integer.length - 1) / 3)

  if (index < R.length(LARGE_NUMBER_ABBRS)) {
    return LARGE_NUMBER_ABBRS[index] || ''
  } else {
    return R.last(LARGE_NUMBER_ABBRS)
  }
}

// priority: maxDecimal >> maxLength
export const formatNumberString = (
  numberString: string,
  options: FormatPriceOptions = {}
): string => {
  const {
    minNumberWithoutENotation = 5e-5,
    maxNumberWithoutENotation = 1e10,
    maxLength = 5,
    maxDecimal = 4,
    allowENotation = true,
    eNotationDecimal = 2,
    intOnlyOverOne = false,
    allowedLargeNumberAbbr = true,
    largeNumberAbbrMaxDecimal = 1,
    priorities = ['maxDecimal', 'maxLength']
  } = options

  const tokenPrice = numberString.includes('e')
    ? Number.parseFloat(numberString).toLocaleString('en', {
        maximumSignificantDigits: 15,
        useGrouping: false
      })
    : numberString

  if (Number.isNaN(Number(tokenPrice)) || tokenPrice === '0') {
    return '0'
  }

  const numValue = Number.parseFloat(numberString)

  const shouldShowENotation =
    allowENotation &&
    (numValue < minNumberWithoutENotation ||
      (numValue > maxNumberWithoutENotation && !allowedLargeNumberAbbr))

  const [integer = '', decimal = ''] = tokenPrice.split('.')

  if (shouldShowENotation) {
    return convertExponentialPrice(tokenPrice, maxLength, eNotationDecimal)
  }

  if (allowedLargeNumberAbbr) {
    const abbr = getLargeNumberAbbr(numValue)

    if (abbr) {
      const pow = Math.pow(10, LARGE_NUMBER_ABBRS.indexOf(abbr) * 3)

      const value = numValue / pow

      return `${value.toLocaleString('en', {
        maximumFractionDigits: largeNumberAbbrMaxDecimal
      })}${abbr}`
    }
  }

  if (maxLength === 0) {
    // no need to truncate

    return numValue.toLocaleString('en', {
      maximumFractionDigits: maxDecimal
    })
  }

  const numberOptions: Intl.NumberFormatOptions =
    integer !== '0'
      ? {
          maximumSignificantDigits: maxLength
        }
      : {
          maximumFractionDigits:
            priorities.indexOf('maxDecimal') < priorities.indexOf('maxLength')
              ? maxDecimal
              : maxLength
        }

  let displayNumber = Number.parseFloat(tokenPrice).toLocaleString(
    'en',
    numberOptions
  )

  if (intOnlyOverOne && integer !== '0') {
    displayNumber = Number.parseFloat(tokenPrice).toLocaleString('en', {
      maximumFractionDigits: 0
    })
  }

  if (integer !== '0' && isDecimalOverflow(displayNumber, maxDecimal)) {
    displayNumber = Number.parseFloat(tokenPrice).toLocaleString('en', {
      maximumFractionDigits: maxDecimal
    })
  }

  if (
    displayNumber.length < maxLength &&
    decimal.length > 0 &&
    !intOnlyOverOne
  ) {
    if (displayNumber.includes('.')) {
      const [newInteger, newDecimal] = displayNumber.split('.')

      const updatedDecimal = `${newDecimal}${'0'.repeat(
        Math.min(
          maxLength - displayNumber.length + 1,
          maxDecimal - newDecimal.length,
          decimal.length - newDecimal.length
        )
      )}`

      displayNumber = `${newInteger}.${updatedDecimal}`
    } else if (maxDecimal > 0) {
      displayNumber = `${displayNumber}.${'0'.repeat(
        Math.min(maxLength - displayNumber.length, maxDecimal)
      )}`
    }
  }

  return displayNumber
}

export const USD_OPTIONS: FormatPriceOptions = {
  maxDecimal: 2,
  minNumberWithoutENotation: 0.005,
  allowENotation: true
}

export const CARD_USD_OPTIONS: FormatPriceOptions = {
  ...USD_OPTIONS,
  intOnlyOverOne: true
}

export const DETAIL_USD_OPTIONS: FormatPriceOptions = {
  ...USD_OPTIONS,
  allowedLargeNumberAbbr: false,
  maxLength: 10
}

export const DETAIL_WETH_OPTIONS: FormatPriceOptions = {
  minNumberWithoutENotation: 5e-10,
  maxLength: 10,
  maxDecimal: 9,
  allowedLargeNumberAbbr: false
}
