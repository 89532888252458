import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, ButtonProps, styled } from '@mui/material'
import { FC } from 'react'

const StyledButton = styled(Button)`
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid #8f939b;
  display: flex;
  justify-content: center;
  min-width: unset;
  width: 50px;
`

const StyledArrowBackIcon = styled(ArrowBackIcon)`
  color: #8f939b;
  font-size: 32px;
`

const ApeironCircularBackButton: FC<Props> = (props: Props) => {
  return (
    <StyledButton {...props}>
      <StyledArrowBackIcon className='Custom-Arrow-Back-Icon' />
    </StyledButton>
  )
}

type Props = ButtonProps

export default ApeironCircularBackButton
