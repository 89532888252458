import { OutlinedInput, styled } from '@mui/material'

export const TextInput = styled(OutlinedInput)`
  & {
    background-color: #161616;
    color: #ffffff;
    border-radius: 2px;
  }

  & .MuiOutlinedInput-input {
    font-size: 12px;
    line-height: 14px;
    padding: 4px;
  }

  &:hover .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-notchedOutline {
    border-color: #161616;
    border-width: 1px;
    border-radius: 2px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffbf36;
    border-width: 1px;
  }
`
