import { MuiLoading, NextImage, RoninSVG } from '@apeiron/library'
import {
  Box,
  Button,
  Snackbar,
  SnackbarCloseReason,
  styled,
  Typography
} from '@mui/material'
import ApeironCloseButton from '@src/components/share/apeiron/ApeironCloseButton'
import { MobileWalletSnackBarMode } from '@src/constants/wallet'
import { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'

const StyledSnackBar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    display: flex;
    flex-wrap: nowrap;
  }

  .MuiSnackbarContent-action {
    margin-right: 0px;
  }
`

const RoninWalletIcon = styled(NextImage)`
  filter: grayscale(100%) brightness(3);
  width: 24px;
  height: 24px;
`

const OpenAppButton = styled(Button)`
  background-color: #1473e9;
  color: white;
  margin-right: 8px;

  :hover,
  :active,
  :focus,
  :visited {
    background-color: #005bd7;
  }
`

const MessageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const MessageHeader = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
`

const MessageDescription = styled(Typography)`
  font-size: 12px;
`

const CloseButton = styled(ApeironCloseButton)`
  width: 16px;
`

const OpenRoninWalletAppSnackBar = ({
  open,
  onClose,
  onOpenApp,
  mode,
  loading
}: Props) => {
  const { t } = useTranslation()

  const buttonContent = () => {
    if (loading) {
      return <MuiLoading loading size={24} />
    } else if (mode === MobileWalletSnackBarMode.Connect) {
      return t('common.button.connect')
    }

    return t('common.button.sign')
  }

  const action = (
    <>
      <OpenAppButton onClick={onOpenApp} disabled={loading}>
        {buttonContent()}
      </OpenAppButton>
      <CloseButton onClick={onClose} />
    </>
  )

  const getMessage = () => {
    switch (mode) {
      case MobileWalletSnackBarMode.Connect:
        return t('wallet.connect_wallet')
      case MobileWalletSnackBarMode.SignMessage:
        return t('wallet.sign_message')
      case MobileWalletSnackBarMode.SignTransaction:
        return t('wallet.sign_transaction')
      default:
        return undefined
    }
  }

  const message = getMessage()

  return (
    <StyledSnackBar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      disableWindowBlurListener
      onClose={onClose}
      message={
        <MessageContainer>
          <RoninWalletIcon src={RoninSVG.src} />
          <Box>
            <MessageHeader>{t('login.general.button.ronin')}</MessageHeader>
            <MessageDescription>{message}</MessageDescription>
          </Box>
        </MessageContainer>
      }
      action={action}
    />
  )
}

type Props = {
  open: boolean
  onClose: (event: SyntheticEvent | Event, reason?: SnackbarCloseReason) => void
  onOpenApp: () => void
  mode: MobileWalletSnackBarMode
  loading: boolean
}

export default OpenRoninWalletAppSnackBar
