import { ApeironLinkButton, NextImage } from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import InvertInfoSVG from '@public/icons/icon-info-invert.svg'
import ENV_CONFIG from '@src/config'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const Container = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`

const InfoIcon = styled(NextImage)`
  aspect-ratio: 1;
  margin-top: 2px;
  width: 16px;
`

const Description = styled(Typography)`
  font-size: 12px;
`

const StyledApeironLink = styled(ApeironLinkButton)`
  color: #3682eb;
  line-height: 14px;
  margin-bottom: 2px;
`

const LoginAgreement: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <InfoIcon src={InvertInfoSVG} />
      <Description>
        <Trans
          components={{
            lnk: (
              <StyledApeironLink
                url={ENV_CONFIG.EXTERNAL_URL.APEIRON_TERMS_CONDITION}
                target='_blank'
              />
            ),
            lnk2: (
              <StyledApeironLink
                url={ENV_CONFIG.EXTERNAL_URL.APEIRON_PRIVACY_POLICY}
                target='_blank'
              />
            )
          }}
        >
          {t('login.general.misc.hint')}
        </Trans>
      </Description>
    </Container>
  )
}

export default LoginAgreement
