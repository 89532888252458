import {
  CurrencyToken,
  CurrencyTokenType,
  EmptyPublicAccount,
  FullAccount,
  MayNull,
  PublicAccount,
  SkeletonTypography,
  useAccountContext,
  useGetPublicAccountDetail
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import TokenSection from '@src/components/account/header/TokenSection'
import PlayerIcon from '@src/components/account/menu/PlayerIcon'
import AdeptusBelieverButton from '@src/components/adeptus/AdeptusBelieverButton'
import ApeironEllipsisTypography from '@src/components/share/apeiron/ApeironEllipsisTypography'
import CopyButton from '@src/components/share/generic/CopyButton'
import { padNumber } from '@src/util/tools/string'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAsyncEffect from 'use-async-effect'

const Container = styled(Box)`
  width: 100%;
  display: flex;
  column-gap: 30px;
  background-color: #4a4d5e;

  ${props => props.theme.breakpoints.up('res1280')} {
    padding: 16px 90px;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
    padding: 16px 5%;
  }
`

const PlayerContainer = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  ${props => props.theme.breakpoints.up('res1280')} {
    gap: 10px;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    flex-direction: column;
  }
`

const StyledPlayerIcon = styled(PlayerIcon)`
  width: 160px;
`

const PlayerBriefContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 14px;
`

const PlayerInfoContainer = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 25px;
  ${props => props.theme.breakpoints.up('res1280')} {
    max-width: 200px;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    max-width: 160px;
  }
`

const PlayNameContainer = styled(Box)`
  align-items: center;
  display: flex;
  gap: 5px;
  width: 100%;
`

const PlayerName = styled(SkeletonTypography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.Custom-Typography-Skeleton {
    ${props => props.theme.breakpoints.up('res480')} {
      min-width: 150px;
    }
    ${props => props.theme.breakpoints.down('res480')} {
      min-width: 70px;
    }
  }
`

const PlayerID = styled(SkeletonTypography)`
  color: #b9bbbe;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
  &.Custom-Typography-Skeleton {
    min-width: 50px;
  }
`

const PlayerOtherInfoContainer = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

const PlayerEmail = styled(ApeironEllipsisTypography)`
  color: #b1b3b3;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  width: 100%;
`

const PlayerAddressContainer = styled(Box)`
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;
`

const PlayerAddress = styled(ApeironEllipsisTypography)`
  color: #b1b3b3;
  overflow: hidden;
  width: 100%;
  ${props => props.theme.breakpoints.up('res1280')} {
    font-size: 14px;
    line-height: 14px;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    font-size: 12px;
    line-height: 12px;
  }
  &.Custom-Typography-Skeleton {
    min-width: 180px;
  }
`

const StatContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  ${props => props.theme.breakpoints.up('res1280')} {
    justify-content: flex-start;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    justify-content: center;
  }
`

const OffchainTokenSection = styled(TokenSection)`
  .Custom-Title {
    color: #6eeae6;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    width: 100%;
  }
`

const OnChainTokenSection = styled(TokenSection)`
  .Custom-Title {
    color: #90ffd5;
  }
  ${props => props.theme.breakpoints.down('res1280')} {
    width: 100%;
  }
`

const extractTokens = (types: CurrencyTokenType[], account: FullAccount) => {
  const doExtract = R.pipe<
    any,
    MayNull<CurrencyToken>[],
    CurrencyToken[],
    CurrencyToken[]
  >(
    R.map(type => {
      return R.find(
        (currency: MayNull<CurrencyToken>) => currency?.type === type,
        Object.values(account.balances || {})
      )
    }),
    R.reject(R.isNil),
    R.reject((token: CurrencyToken) => {
      return token.amount === '0'
    })
  )

  return doExtract(types)
}

const MyAccount: FC<Props> = props => {
  const { className } = props

  const { t } = useTranslation()

  const { account, isLoggedIn, fetchBalances } = useAccountContext()

  const { pathname } = useRouter()

  const offchainTokens = useMemo(() => {
    const types = [
      CurrencyTokenType.Animus,
      CurrencyTokenType.Aprm,
      CurrencyTokenType.Stardust
    ]

    return extractTokens(types, account)
  }, [account])

  const onChainTokens = useMemo(() => {
    const types = [
      CurrencyTokenType.Ron,
      CurrencyTokenType.WRon,
      CurrencyTokenType.Weth,
      CurrencyTokenType.Usdc,
      CurrencyTokenType.Anima,
      CurrencyTokenType.Aprs
    ]

    return extractTokens(types, account)
  }, [account])

  useEffect(() => {
    if (account.walletAddress) {
      fetchBalances()
    }
  }, [account.walletAddress, pathname])

  const loading = R.isEmpty(account.name)

  return isLoggedIn ? (
    <Container className={className}>
      <PlayerContainer>
        <PlayerBriefContainer>
          <StyledPlayerIcon />
          <PlayerInfoContainer>
            <PlayNameContainer>
              <PlayerName loading={loading}>{account.name}</PlayerName>
              <PlayerID loading={loading}>
                # {padNumber(account.tag, 4)}
              </PlayerID>
            </PlayNameContainer>
            <PlayerOtherInfoContainer>
              {account.email ? (
                <>
                  <PlayerEmail>{account.email}</PlayerEmail>
                  <PlayerAddressContainer>
                    <PlayerAddress loading={loading}>
                      {account.walletAddress}
                    </PlayerAddress>
                    <CopyButton value={account.walletAddress} />
                  </PlayerAddressContainer>
                  <AdeptusBelieverButton account={account} loading={loading} />
                </>
              ) : (
                <PlayerAddressContainer>
                  <PlayerAddress loading={loading}>
                    {account.walletAddress}
                  </PlayerAddress>
                  <CopyButton value={account.walletAddress} />
                </PlayerAddressContainer>
              )}
            </PlayerOtherInfoContainer>
          </PlayerInfoContainer>
        </PlayerBriefContainer>
      </PlayerContainer>
      <StatContainer>
        <OnChainTokenSection
          title={t('currency_group.onchain')}
          tokens={onChainTokens}
        />
        <OffchainTokenSection
          title={t('currency_group.offchain')}
          tokens={offchainTokens}
        />
      </StatContainer>
    </Container>
  ) : (
    <></>
  )
}

const OtherAccount: FC<Props> = props => {
  const { className } = props

  const { query } = useRouter()

  const ownerWalletAddress = R.propOr(
    '',
    'ownerWalletAddresses',
    query
  ) as string

  const { fetch: getPublicAccountDetail } = useGetPublicAccountDetail()

  const [account, setAccount] = useState<PublicAccount | null>(null)

  useAsyncEffect(
    async (isActive: () => boolean) => {
      if (!R.isEmpty(ownerWalletAddress)) {
        let accountFetched: PublicAccount | null = await getPublicAccountDetail(
          {
            walletAddress: ownerWalletAddress
          }
        )

        if (!isActive()) {
          return
        }

        // no account found / but only address
        if (R.isNil(accountFetched)) {
          accountFetched = {
            ...EmptyPublicAccount,
            walletAddress: ownerWalletAddress
          }
        }

        setAccount(accountFetched)
      }
    },
    [ownerWalletAddress]
  )

  const loading = R.isNil(account)

  return (
    <Container className={className}>
      <PlayerContainer>
        <PlayerBriefContainer>
          <StyledPlayerIcon />
          <PlayerInfoContainer>
            <PlayNameContainer>
              <PlayerName loading={loading}>{account?.name}</PlayerName>
              <PlayerID loading={loading}>
                # {padNumber(account?.tag || 0, 4)}
              </PlayerID>
            </PlayNameContainer>
            <PlayerOtherInfoContainer>
              <PlayerAddressContainer>
                <PlayerAddress loading={loading}>
                  {account?.walletAddress}
                </PlayerAddress>
                <CopyButton value={account?.walletAddress || ''} />
              </PlayerAddressContainer>
              <AdeptusBelieverButton account={account} loading={loading} />
            </PlayerOtherInfoContainer>
          </PlayerInfoContainer>
        </PlayerBriefContainer>
      </PlayerContainer>
    </Container>
  )
}

const AccountHeader: FC<Props> = props => {
  const { query, isReady } = useRouter()

  const { ownerWalletAddresses } = query

  const { account } = useAccountContext()

  const visitorMode =
    !R.isNil(ownerWalletAddresses) &&
    account.walletAddress !== ownerWalletAddresses

  if (!isReady) {
    // wait until router ready to detect "ownerWalletAddresses"
    return <></>
  }

  return visitorMode ? <OtherAccount {...props} /> : <MyAccount {...props} />
}

type Props = {
  className?: string
}

export default AccountHeader
