import {
  AdeptusCodeInput,
  AdeptusInputMode,
  ApeironLinkButton,
  isIosOrAndroid,
  NextImage
} from '@apeiron/library'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import LogoPNG from '@public/icons/icon-logo.png'
import CommunityAuthSection from '@src/components/account/login/CommunityAuthSection'
import ConnectWalletButton from '@src/components/account/login/ConnectWalletButton'
import EmailLoginComponent from '@src/components/account/login/EmailLoginComponent'
import LoginAgreement from '@src/components/account/login/LoginAgreement'
import { LOGIN_SLIDE_STATE } from '@src/constants/login'
import theme from '@src/styles/theme'
import { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSwiper } from 'swiper/react'

const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 680px;
  position: relative;
  background-color: #1c1f25;
  padding: 40px;
  ${props => props.theme.breakpoints.up('res1024')} {
    width: 560px;
    overflow-y: auto;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100%;
  }
`

const Logo = styled(NextImage)`
  width: 151px;
  min-height: 151px;
  height: 36px;
  min-height: 36px;
`

const Title = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
`

const Description = styled(Typography)`
  font-size: 12px;
`

const SignupDescription = styled(Typography)`
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 4px;
`

const WalletButtonContainer = styled(Box)`
  display: flex;
  gap: 10px;
`

const Separator = styled(Box)`
  border-top: 1px dashed #979797;
  margin: 16px 0px;
  width: 100%;
`

const SignupLink = styled(ApeironLinkButton)`
  color: #3682eb;
  font-size: 12px;
`

const CodeInput = styled(AdeptusCodeInput)`
  margin-bottom: 16px;
  width: 100%;
`

const RootLoginSection: FC<Props> = (props: Props) => {
  const { className, onRoninLogin } = props

  const swiper = useSwiper()

  const { t } = useTranslation()

  const mobile = useMediaQuery(theme.breakpoints.down('res1024'))

  const handleOnSignUp = useCallback(() => {
    swiper.slideTo(LOGIN_SLIDE_STATE.SIGN_UP)
  }, [swiper])

  const handleConnectClick = useCallback(() => {
    if ((isIosOrAndroid() && !window.ronin) || window.okxwallet) {
      swiper.slideTo(LOGIN_SLIDE_STATE.SELECT_WALLET)
    } else {
      onRoninLogin()
    }
  }, [swiper])

  return (
    <MainContainer className={className}>
      {!mobile && <Logo src={LogoPNG} />}
      <Title>{t('login.email.title')}</Title>
      <Description>{t('login.email.subtitle')}</Description>
      <EmailLoginComponent />
      <SignupDescription>
        <Trans
          components={{
            lnk: <SignupLink onClick={handleOnSignUp} />
          }}
        >
          {t('login.email.signup')}
        </Trans>
      </SignupDescription>
      <Separator />
      <WalletButtonContainer>
        <ConnectWalletButton onClick={handleConnectClick} />
      </WalletButtonContainer>
      <CommunityAuthSection />
      <CodeInput showFoot={false} mode={AdeptusInputMode.AccountLogin} />
      <LoginAgreement />
    </MainContainer>
  )
}

type Props = {
  className?: string
  onRoninLogin: () => void
}

export default RootLoginSection
