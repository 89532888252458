import { ApeironBlueButton, NextImage, RoninSVG } from '@apeiron/library'
import { styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const RoninWalletButton = styled(ApeironBlueButton)`
  --background-color: #1473e9;
  border-radius: 4px;
  color: white;
  flex: 1;
  font-size: 16px;
  gap: 6px;
  height: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  width: 100%;
  :hover {
    filter: brightness(0.9);
  }
`

const RoninWalletIcon = styled(NextImage)`
  filter: grayscale(100%) brightness(3);
  width: 24px;
  height: 24px;
`

const ConnectWalletButton = ({ onClick }: Props) => {
  const { t } = useTranslation()

  return (
    <RoninWalletButton onClick={onClick}>
      <RoninWalletIcon src={RoninSVG} />
      {t('login.general.button.connect_wallet')}
    </RoninWalletButton>
  )
}

type Props = {
  onClick: () => void
}

export default ConnectWalletButton
