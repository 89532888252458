import { SortByItem } from '@src/types/filter'

// enum - apostle list mode
export enum ApostleListMode {
  EMPORIUM = 0,
  INVENTORY,
  OTHERS_INVENTORY,
  FAVORITE,
  POPUP_SELECT_INVENTORY
}

export enum ReservedApostleState {
  Reserved = 'Reserved',
  Minted = 'Minted'
}

// for mint page display
export enum ReservedApostleSlotState {
  Unknown = 0,
  Empty,
  Locked,
  Reserved // have apostle
}

export enum ApostleTicketItemType {
  Origin = 'origin',
  Season = 'season',
  Unknown = 'unknown'
}

export enum ApostleSourceType {
  None = 'TicketMintTypeNone',
  Origin = 'TicketOrigin',
  S2Golden = 'TicketS2GoldenApostle',
  S2 = 'TicketS2Apostle',
  S2RGolden = 'TicketS2RGoldenApostle',
  S2R = 'TicketS2RApostle',
  ZombieSummon = 'TicketZombieSummoner'
}

// ? align with game server
export enum ApostleClassType {
  Bard = 'Bard',
  Berserker = 'Berserker',
  Dragoon = 'Dragoon',
  Guardian = 'Guardian',
  Hunter = 'Hunter',
  Knight = 'Knight',
  Mage = 'Mage',
  Priest = 'Priest',
  Rogue = 'Rogue',
  Shaman = 'Shaman',
  Summoner = 'Summoner',
  Warrior = 'Warrior'
}

// ? align with server
export enum ApostleClassId {
  Warrior = 1,
  Guardian,
  Rogue,
  Berserker,
  Knight,
  Hunter,
  Mage,
  Summoner,
  Dragoon,
  Bard,
  Priest,
  Shaman
}

export enum ApostleStatus {
  Enable = 'Enable',
  Staked = 'Staked',
  Disable = 'Disable'
}

export enum ApostleTicketType {
  Bard = 'Bard',
  Berserker = 'Berserker',
  Dragoon = 'Dragoon',
  Guardian = 'Guardian',
  Hunter = 'Hunter',
  Knight = 'Knight',
  Mage = 'Mage',
  Priest = 'Priest',
  Rogue = 'Rogue',
  Shaman = 'Shaman',
  Summoner = 'Summoner',
  Warrior = 'Warrior',
  S2Normal = 'S2_Normal',
  S2Golden = 'S2_Golden',
  S2RNormal = 'S2R_Normal',
  S2RGolden = 'S2R_Golden',
  ZombieSummoner = 'Zombie_Summoner'
}

export enum ApostleTicketStatus {
  Enable = 'Enable',
  Staked = 'Staked',
  Disable = 'Disable'
}

export const S2TIcketIDs = ['10001', '10002', '10003']

export enum ApostleSkillType {
  AutoAttack = 'Auto Attack',
  Innate = 'Energized skill',
  ActiveSkill = 'Active skill'
}

export enum ApostleSkillSpecialId {
  AutoAttack = '99',
  Innate = '00'
}

// enum - star sort options (align with backend)
export enum ApostleSortBy {
  IDDesc = 'IDDesc',
  IDAsc = 'IDAsc',
  PriceDesc = 'PriceDesc',
  PriceAsc = 'PriceAsc',
  ListAtDesc = 'ListingTimeDesc',
  UpdateAtDesc = 'UpdateAtDesc',
  ApostleIVAsc = 'ApostleIVAsc',
  ApostleIVDesc = 'ApostleIVDesc'
}

// const - use on sorting general apostle list
export const ApostleNormalSortItems: SortByItem<ApostleSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: ApostleSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: ApostleSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: ApostleSortBy.IDAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: ApostleSortBy.IDDesc
  },
  {
    id: 4,
    nameId: 'list.sort_apostle_iv_asc',
    value: ApostleSortBy.ApostleIVAsc
  },
  {
    id: 5,
    nameId: 'list.sort_apostle_iv_desc',
    value: ApostleSortBy.ApostleIVDesc
  },
  {
    id: 6, // latest
    nameId: 'list.sort_updated_at_desc',
    value: ApostleSortBy.UpdateAtDesc
  }
]

// const - use on sorting emporium apostle list
export const ApostleEmporiumSortItems: SortByItem<ApostleSortBy>[] = [
  { id: 0, nameId: 'list.sort_price_asc', value: ApostleSortBy.PriceAsc },
  { id: 1, nameId: 'list.sort_price_desc', value: ApostleSortBy.PriceDesc },
  {
    id: 2,
    nameId: 'list.sort_id_asc',
    value: ApostleSortBy.IDAsc
  },
  {
    id: 3,
    nameId: 'list.sort_id_desc',
    value: ApostleSortBy.IDDesc
  },
  {
    id: 4,
    nameId: 'list.sort_apostle_iv_asc',
    value: ApostleSortBy.ApostleIVAsc
  },
  {
    id: 5,
    nameId: 'list.sort_apostle_iv_desc',
    value: ApostleSortBy.ApostleIVDesc
  },
  {
    id: 6, // latest
    nameId: 'list.sort_list_at_desc',
    value: ApostleSortBy.ListAtDesc
  }
]

// enum - used in apostle list filter
export const enum TicketMintEnum {
  TicketMintTypeNone = 'TicketMintTypeNone',
  TicketOrigin = 'TicketOrigin',
  TicketS2GoldenApostle = 'TicketS2GoldenApostle',
  TicketS2Apostle = 'TicketS2Apostle',
  TicketZombieSummoner = 'TicketZombieSummoner'
}

export enum ApostleAttachAction {
  None = 'None',
  Burn = 'Burn',
  Mint = 'Mint',
  Reforge = 'Reforge',
  UpdateGene = 'UpdateGene'
}

export const DOODLE_ITEM_TOOLTIP_ID = 'doodle-item-tooltip-id'

export const MAX_APOSTLE_NAME = 20

export const NO_REPLACE_INDEX = -1

export const APOSTLE_CYCLE_BACKEND_NAME = 'RAC'
