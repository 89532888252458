import {
  INFO_POPUP_TYPE,
  InfoPopup,
  MayNull,
  NextLink,
  PublicAccount,
  useAccountContext
} from '@apeiron/library'
import { styled } from '@mui/material'
import ApeironEllipsisTypography from '@src/components/share/apeiron/ApeironEllipsisTypography'
import ApeironPagingDataGrid, {
  AgDatasourceResult
} from '@src/components/share/apeiron/ApeironPagingDataGrid'
import ApeironSimpleButton from '@src/components/share/apeiron/ApeironSimpleButton'
import { ROUTE } from '@src/constants/route'
import useWalletAddress from '@src/hooks/contract/useWalletAddress'
import useGetAccountInvitees from '@src/hooks/graphql/useGetAccountInvitees'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { format } from 'date-fns'
import queryString from 'query-string'
import * as R from 'ramda'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const BelieverButton = styled(ApeironSimpleButton)`
  border-radius: 100px;
  border: 1px solid white;
  font-size: 14px;
  font-weight: 300;
  padding: 1px 18px;
  :hover {
    background-color: unset;
  }
  :disabled {
    background-color: unset;
    color: white;
    opacity: 0.2;
  }
  :focus {
    background-color: unset;
  }
  strong {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
  }
  ${props => props.theme.breakpoints.up('res1024')} {
    min-width: unset;
  }
`

const DataGrid = styled(ApeironPagingDataGrid)`
  padding: 1%;
  margin: auto;
  width: 95%;
  .Custom-Grid {
    .ag-row {
      border-bottom: 1px solid #232326;
    }
    .ag-header-row {
      background-color: #151615;
    }
  }
`

const Link = styled(NextLink)`
  text-decoration: underline;
`

const Text = styled(ApeironEllipsisTypography)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Popup = styled(InfoPopup)`
  height: 90%;
  max-height: 580px;
  max-width: 900px;
  width: 100%;
  .Custom-Content {
    height: 100%;
    overflow-y: auto;
  }
`

const AdeptusBelieverButton: FC<Props> = props => {
  const { t } = useTranslation()

  const { account, className, loading } = props

  const { account: loginAccount } = useAccountContext()

  const { compareAddress } = useWalletAddress()

  const isLoginAccount = compareAddress(
    account?.walletAddress,
    loginAccount.walletAddress
  )

  const [open, setOpen] = useState(false)

  const handleOnToggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  const { fetch } = useGetAccountInvitees()

  const columnDef: ColDef[] = useMemo(() => {
    return [
      {
        cellClass: 'custom-ag-cell-center',
        field: 'start',
        headerClass: 'custom-ag-header-center',
        headerName: '',
        pinned: 'left',
        sortable: false,
        width: 10
      },
      {
        cellClass: 'custom-ag-cell-center',
        field: 'name',
        headerClass: 'custom-ag-header-center',
        headerName: t('common.grid.name'),
        minWidth: 250,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<PublicAccount>) => {
          return <Text>{params.data?.name}</Text>
        }
      },
      {
        cellClass: 'custom-ag-cell-center',
        field: 'walletAddress',
        flex: 1,
        headerClass: 'custom-ag-header-center',
        headerName: t('common.grid.address'),
        minWidth: 300,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<PublicAccount>) => {
          if (R.isNil(params.data?.walletAddress)) return <>{' - '}</>

          const url = queryString.stringifyUrl({
            url: ROUTE.OTHER_INVENTORY_PLANET_LIST,
            query: {
              ownerWalletAddresses: params.data?.walletAddress
            }
          })

          return (
            <Text>
              <Link href={url} target='_blank'>
                {params.data?.walletAddress}
              </Link>
            </Text>
          )
        }
      },
      {
        cellClass: 'custom-ag-cell-center',
        field: 'createdAt',
        headerClass: 'custom-ag-header-center',
        headerName: t('adeptus.believer.join_date'),
        width: 250,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<PublicAccount>) => {
          const createdAt = params.data?.createdAt

          return (
            <Text>
              {createdAt ? format(new Date(createdAt), 'yyyy-MM-dd') : ''}
            </Text>
          )
        }
      }
    ]
  }, [t])

  const handleOnFetch = useCallback(
    async (page: number, pageSize: number): Promise<AgDatasourceResult> => {
      const result = await fetch({ isInvitee: true, pageSize, page })

      return {
        data: result.data,
        total: result.pageInfo.total
      }
    },
    [fetch]
  )

  return (
    <>
      <BelieverButton
        className={className}
        disabled={loading || !isLoginAccount}
        onClick={handleOnToggle}
      >
        <Trans>
          {t('account_header.believer', {
            count: account?.invitation?.believerCount || 0
          })}
        </Trans>
      </BelieverButton>
      {isLoginAccount && (
        <Popup
          open={open}
          type={INFO_POPUP_TYPE.BLACK}
          onClose={handleOnToggle}
        >
          <DataGrid
            columnDefs={columnDef}
            fetch={handleOnFetch}
            rowHeight={40}
          />
        </Popup>
      )}
    </>
  )
}

type Props = {
  account: MayNull<PublicAccount>
  className?: string
  loading?: boolean
}

export default AdeptusBelieverButton
