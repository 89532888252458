import { noForwardProps, SkeletonTypography } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { FC, ReactNode } from 'react'
import MiddleEllipsis from 'react-middle-ellipsis'

const Text = styled(SkeletonTypography, noForwardProps)<{
  __whitespaceWrap?: boolean
}>`
  display: inline;
  white-space: ${({ __whitespaceWrap }) =>
    __whitespaceWrap ? 'normal' : 'nowrap'};
`

const ApeironEllipsisTypography: FC<Props> = props => {
  const { children, className, loading, whitespaceWrap } = props

  return (
    <Box className={className}>
      <MiddleEllipsis>
        <Text
          className={className}
          loading={loading}
          __whitespaceWrap={whitespaceWrap}
        >
          {children}
        </Text>
      </MiddleEllipsis>
    </Box>
  )
}

type Props = {
  children?: ReactNode
  className?: string
  loading?: boolean
  whitespaceWrap?: boolean
}

export default ApeironEllipsisTypography
