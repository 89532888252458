import {
  ApeironLinkButton,
  DownloadInfo,
  DownloadStatus,
  NextImage,
  noForwardProps,
  PlatformType,
  useLanguageContext
} from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import ApeironSVG from '@public/icons/icon-apeiron-logo-simplified.svg'
import { FeatureFlagType } from '@src/constants/featureFlag'
import useFetchBattleDemoConfigJson from '@src/hooks/json/useFetchBattleDemoConfigJson'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import { QRCodeSVG } from 'qrcode.react'
import * as R from 'ramda'
import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAsyncEffect from 'use-async-effect'

const ClientContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 85%;
  min-width: 500px;
  background-color: black;
  border-radius: 8px;
  padding: 20px;
  aspect-ratio: 2.66;
`

const Title = styled(Typography)`
  margin: -10px 0 10px 0;
`

const DownloadContainer = styled(Box)`
  display: flex;
  width: 100%;
  gap: 10px;
`

const RightContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`

const Row = styled(Box)`
  width: calc(50% - 8px);
`

const QRCodeContainer = styled(Box)`
  align-items: center;
  aspect-ratio: 1;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  width: 30%;
`

const QRCode = styled(QRCodeSVG)`
  height: unset;
  padding: 4px;
`

const LinkBlock = styled(ApeironLinkButton, noForwardProps)<ComingSoonProps>`
  background-color: white;
  border-radius: 4px;
  height: 40px;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
  ${props => props.__comingSoon && 'pointer-events: none;'}
`

const LongIcon = styled(NextImage)`
  height: 90%;
  width: 100%;
`

const Icon = styled(NextImage)`
  aspect-ratio: 1;
  height: 32px;
  margin-left: 16.5%;
`

const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Status = styled(Typography)`
  color: black;
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  margin-bottom: -4px;
  white-space: nowrap;
`

const PlatformName = styled(Typography)`
  color: black;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
`

const DownloadSection: FC<DownloadSectionProps> = (
  props: DownloadSectionProps
) => {
  const { language } = useLanguageContext()

  const { info, key } = props

  const comingSoon =
    (R.propOr('', 'status', info) as string) === DownloadStatus.ComingSoon

  const url = comingSoon ? '' : R.pathOr('', ['build', 'url', language], info)

  const darkIcon = R.pathOr('', ['platform', 'icon', 'dark'], info)

  const longIcon = R.pathOr('', ['platform', 'icon', 'long'], info)

  const name = R.pathOr('', ['platform', 'name'], info)

  const imageOnly = R.propOr(false, 'imageOnly', info)

  const status = R.propOr(false, 'statusText', info) as string

  return (
    <LinkBlock key={key} url={url} target='_blank' __comingSoon={comingSoon}>
      {imageOnly ? (
        <LongIcon src={longIcon} />
      ) : (
        <>
          <Icon src={darkIcon} />
          <TextContainer>
            {!R.isEmpty(status) && <Status>{status}</Status>}
            <PlatformName>{name}</PlatformName>
          </TextContainer>
        </>
      )}
    </LinkBlock>
  )
}

const LoginGameClientSection: FC<Props> = (props: Props) => {
  const { className } = props

  const { t } = useTranslation()

  const { fetchJson: fetchConfigJson } = useFetchBattleDemoConfigJson()

  const [downloadInfoList, setDownloadInfoList] = useState<DownloadInfo[]>([])

  useAsyncEffect(async (isActive: () => boolean) => {
    const result = await fetchConfigJson()

    if (!isActive()) {
      return
    }

    if (R.isNotNil(result)) {
      setDownloadInfoList(result.downloadMap.login)
    }
  }, [])

  const epicLink = useMemo(() => {
    const result = R.find((info: DownloadInfo) => {
      return info.platform.type === PlatformType.Epic
    }, downloadInfoList)

    return R.pathOr('', ['build', 'url', 'en_us'], result)
  }, [downloadInfoList])

  return (
    <ClientContainer className={className}>
      <Title>{t('login.general.client_title')}</Title>
      <DownloadContainer>
        {featureAllowed(FeatureFlagType.ShowDownloadQRCode) && (
          <QRCodeContainer>
            <QRCode
              size={138}
              level='L'
              value={epicLink}
              imageSettings={{
                src: ApeironSVG,
                excavate: true,
                width: 18,
                height: 18
              }}
            />
          </QRCodeContainer>
        )}
        <RightContainer>
          {R.map((info: DownloadInfo) => {
            return (
              <Row key={info.platform.type}>
                <DownloadSection info={info} />
              </Row>
            )
          }, downloadInfoList)}
        </RightContainer>
      </DownloadContainer>
    </ClientContainer>
  )
}

type ComingSoonProps = {
  __comingSoon?: boolean
}

type DownloadSectionProps = {
  info: DownloadInfo
  key?: string
}

type Props = {
  className?: string
}

export default LoginGameClientSection
