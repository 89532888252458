import { createStrictContext, singleOperation } from '@apeiron/library'
import { PRELOAD_KEY } from '@src/constants/preload'
import * as R from 'ramda'
import React, { ReactNode, useCallback, useState } from 'react'

const [ContextProvider, usePreloadContext] =
  createStrictContext<ContextType>('Preload')

export { usePreloadContext }

export const PreloadProvider = (props: Props) => {
  const { children } = props

  const [preload, setPreload] = useState<Object>({})

  const preloadValue = useCallback(
    async <T extends unknown>(
      key: PRELOAD_KEY,
      operation: () => Promise<T>
    ): Promise<T> => {
      const operateKey = `preload_${key}`

      if (R.has(operateKey, preload)) {
        return R.prop(operateKey, preload) as T
      } else {
        const value = await singleOperation(operateKey, operation)

        setPreload(preload => R.assoc(operateKey, value, preload))

        return value
      }
    },
    [preload]
  )

  return <ContextProvider value={{ preloadValue }}>{children}</ContextProvider>
}

type ContextType = {
  preloadValue: <T extends unknown>(
    key: PRELOAD_KEY,
    operation: () => Promise<T>
  ) => Promise<T>
}

type Props = {
  children?: ReactNode
}
