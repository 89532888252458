import { createTheme } from '@mui/material'
import React from 'react'

declare module '@mui/material' {
  interface BreakpointOverrides {
    xs: false // disabled
    sm: false // disabled
    md: false // disabled
    lg: false // disabled
    xl: false // disabled
    res320: true // mobile | tablet portrait
    res480: true // mobile | tablet portrait
    res768: true // mobile | tablet portrait
    res1024: true // desktop | tablet landscape (suggested)
    res1280: true // desktop | tablet landscape
    res1600: true // desktop | tablet landscape
    res1920: true // desktop | tablet landscape
  }

  interface TypographyVariants {
    footer: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    footer?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    footer: true
  }
}

const theme = createTheme({
  palette: {
    text: {
      primary: '#ffffff'
    }
  },
  typography: {
    allVariants: {
      color: '#ffffff'
    },
    fontFamily: 'var(--font-Roboto)'
  },
  breakpoints: {
    values: {
      res320: 320,
      res480: 480,
      res768: 768,
      res1024: 1024,
      res1280: 1280,
      res1600: 1600,
      res1920: 1920
    }
  }
})

export default theme
