import { CurrencyTokenType } from '@apeiron/library'

export const SUPPORTED_TOKENS = [
  CurrencyTokenType.Anima,
  CurrencyTokenType.Aprs,
  CurrencyTokenType.Weth,
  CurrencyTokenType.Usdc,
  CurrencyTokenType.WRon,
  CurrencyTokenType.Ron
]
