import {
  AssetType,
  DownloadInfo,
  downloadInfoSchema,
  DownloadMap,
  useApeironPathMaker,
  useFetchJson
} from '@apeiron/library'
import CONFIG from '@src/config'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import { battleDemoNewsSchema } from '@src/deserialize/yup/battleDemo'
import { BattleDemoConfig, BattleDemoNewsList } from '@src/types/battleDemo'
import * as R from 'ramda'
import { useCallback } from 'react'

const useFetchBattleDemoConfigJson = (): Hook => {
  const { makePath } = useApeironPathMaker()

  const { preloadValue } = usePreloadContext()

  const { fetchJson: fetchDownloads } = useFetchJson({
    url: makePath(AssetType.Json, CONFIG.JSON_FILE_NAME.DOWNLOAD)
  })

  const { fetchJson: fetchBuildInfo } = useFetchJson({
    url: CONFIG.JSON_URL.BATTLE_DEMO_CONFIG
  })

  const fetchJson = useCallback(async (): Promise<BattleDemoConfig> => {
    const response = (await fetchDownloads()) as Record<string, Object[]>

    const config = (await fetchBuildInfo()) as Object[]

    const validate = (data: Object): DownloadInfo => {
      return downloadInfoSchema.cast(data, {
        context: {
          data,
          info: R.propOr({}, 'client_version', config)
        }
      }) as DownloadInfo
    }

    const downloadMap: DownloadMap = {
      battleDemo: R.map(validate, response.battleDemo || []),
      foonieMagus: R.map(validate, response.battleDemo || []),
      login: R.map(validate, response.login || []),
      officialSite: R.map(validate, response.officialSite || [])
    }

    const news = battleDemoNewsSchema.validateSync(
      R.propOr({}, 'news', config)
    ) as BattleDemoNewsList

    return { downloadMap, news }
  }, [fetchBuildInfo, fetchDownloads])

  const fetchWithPreload = useCallback(async () => {
    return preloadValue(PRELOAD_KEY.BATTLE_DEMO_CONFIG, fetchJson)
  }, [fetchJson, preloadValue])

  return {
    fetchJson: fetchWithPreload
  }
}

type Hook = {
  fetchJson: () => Promise<BattleDemoConfig>
}

export default useFetchBattleDemoConfigJson
