import { gql } from '@apollo/client'

export default gql`
  query GetTokenPriceToUSD($currencies: [CurrencyCode!]) {
    getTokenPriceToUSD(currencyCode: $currencies) {
      currencyCode
      price
    }
  }
`
