import { DEFAULT_ANIMATE_DURATION, NextImage } from '@apeiron/library'
import { ButtonBase, ButtonProps, styled } from '@mui/material'
import CloseSVG from '@public/icons/icon-close.svg'
import { FC } from 'react'

const StyledButton = styled(ButtonBase)`
  aspect-ratio: 1;
  width: 32px;
`

const CloseIcon = styled(NextImage)`
  transition: stroke ${DEFAULT_ANIMATE_DURATION}ms,
    fill ${DEFAULT_ANIMATE_DURATION}ms;
  filter: invert(76%);
  height: 100%;
  width: 100%;
  :hover {
    filter: invert(83%);
  }
  :active {
    filter: invert(88%);
  }
`

const ApeironCloseButton: FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <StyledButton disableRipple {...props}>
      <CloseIcon src={CloseSVG} />
    </StyledButton>
  )
}

export default ApeironCloseButton
