import {
  deserializeListData,
  OnChainTokenToUSD,
  onChainTokenToUsdSchema
} from '@apeiron/library'
import { useQuery } from '@apollo/client'
import { SUPPORTED_TOKENS } from '@src/constants/currency'
import graphql from '@src/graphql/query/getTokenPriceToUSD'
import { useCallback, useMemo } from 'react'

const useGetTokenPriceToUSD = (): Hook => {
  const currencies = Object.values(SUPPORTED_TOKENS)

  const {
    data: response,
    loading,
    refetch: refetchRatio
  } = useQuery(graphql, { variables: { currencies } })

  const data = useMemo(
    () =>
      deserializeListData<OnChainTokenToUSD>(
        { data: response },
        onChainTokenToUsdSchema
      ),
    [response]
  )

  const refetch = useCallback(() => {
    refetchRatio()
  }, [refetchRatio])

  return {
    data,
    loading,
    refetch
  }
}

type Response = OnChainTokenToUSD[]

type Hook = {
  data: Response
  loading: boolean
  refetch: () => void
}

export default useGetTokenPriceToUSD
