import { currencyTokenLiteSchema, publicAccountSchema } from '@apeiron/library'
import {
  ApostleClassId,
  ApostleClassType,
  ApostleSkillType,
  ApostleSourceType,
  ApostleStatus,
  ApostleTicketItemType,
  ApostleTicketStatus,
  ApostleTicketType,
  ReservedApostleSlotState,
  ReservedApostleState
} from '@src/constants/apostle'
import {
  listingInfoSchema,
  listingStatusSchema
} from '@src/deserialize/yup/listingInfo'
import {
  Apostle,
  ApostleBase,
  ApostleClass,
  ApostleLite,
  ApostleMint,
  ApostleReforgePreview,
  ApostleSignedSeasonMint,
  ApostleSkill,
  ApostleStats,
  ApostleTicket,
  ReservedApostle,
  TicketRandomFactor
} from '@src/types/apostle'
import * as yup from 'yup'

const apostleClassTypeSchema: yup.SchemaOf<ApostleClassType> = yup
  .mixed()
  .oneOf(Object.values(ApostleClassType))
  .default(ApostleClassType.Berserker)

const apostleClassIdSchema: yup.SchemaOf<ApostleClassId> = yup
  .mixed()
  .oneOf(Object.values(ApostleClassId))
  .default(ApostleClassId.Berserker)

const apostleSourceTypeSchema: yup.SchemaOf<ApostleSourceType> = yup
  .mixed()
  .oneOf(Object.values(ApostleSourceType))
  .default(ApostleSourceType.None)

const apostleClassSchema: yup.SchemaOf<ApostleClass> = yup
  .object({
    icon: yup.string().default(''),
    name: yup.string().default(''),
    type: apostleClassTypeSchema
  })
  .noUnknown(true)

const apostleStatusSchema: yup.SchemaOf<ApostleStatus> = yup
  .mixed()
  .oneOf(Object.values(ApostleStatus))
  .default(ApostleStatus.Enable)

const apostleTicketStatusSchema: yup.SchemaOf<ApostleTicketStatus> = yup
  .mixed()
  .oneOf(Object.values(ApostleTicketStatus))
  .default(ApostleTicketStatus.Enable)

const apostleSkillTypeSchema: yup.SchemaOf<ApostleSkillType> = yup
  .mixed()
  .oneOf(Object.values(ApostleSkillType))
  .default(ApostleSkillType.ActiveSkill)

const apostleSkillExpSchema = yup
  .object({
    id: yup.number().default(0),
    key: yup.string().default(''),
    level: yup.number().default(0)
  })
  .from('skillID', 'id')

const apostleTicketItemTypeSchema = yup
  .mixed()
  .oneOf(Object.values(ApostleTicketItemType))
  .default(ApostleTicketItemType.Unknown)

const apostleTypeSchema = yup
  .mixed()
  .oneOf(Object.values(ApostleTicketType))
  .default(ApostleTicketType.Warrior)

const reservedApostleStateSchema = yup
  .mixed()
  .oneOf(Object.values(ReservedApostleState))
  .default(ReservedApostleState.Reserved)

const reservedApostleSlotStateSchema = yup
  .mixed()
  .oneOf(Object.values(ReservedApostleSlotState))
  .default(ReservedApostleSlotState.Reserved)

const randomFactorSchema: yup.SchemaOf<TicketRandomFactor> = yup
  .object({
    costume: yup.string().default(''),
    ivMax: yup.number().default(0),
    ivMin: yup.number().default(0),
    skillLevelMax: yup.number().default(0),
    skillLevelMin: yup.number().default(0),
    types: yup.string().default('')
  })
  .camelCase()
  .noUnknown(true)

const apostleStatsSchema: yup.SchemaOf<ApostleStats> = yup
  .object({
    attack: yup.number().default(0),
    attackRange: yup.number().default(0),
    attackSpeed: yup.number().default(0),
    criticalChance: yup.number().default(0),
    criticalDamage: yup.number().default(0),
    defense: yup.number().default(0),
    health: yup.number().default(0),
    intelligence: yup.number().default(0),
    moveSpeed: yup.number().default(0),
    resistance: yup.number().default(0)
  })
  .noUnknown(true)

export const apostleBaseSchema: yup.SchemaOf<ApostleBase> = yup
  .object({
    class: apostleClassSchema,
    disabled: yup.boolean().default(false),
    image: yup.string().default(''),
    id: yup.string().default(''),
    iv: yup.number().default(0),
    selected: yup.boolean().default(false),
    skillExps: yup.array().of(apostleSkillExpSchema).default([]),
    sourceType: apostleSourceTypeSchema
  })
  .noUnknown(true)

export const apostleTicketSchema: yup.SchemaOf<ApostleTicket> = yup
  .object({
    api: yup.string().default(''),
    disabled: yup.boolean().default(false),
    id: yup.string().default(''),
    image: yup.string().default(''),
    itemType: apostleTicketItemTypeSchema,
    iv: yup.number().default(0),
    level: yup.number().default(0),
    name: yup.string().default(''),
    quantity: yup.number().default(0),
    randomFactor: randomFactorSchema,
    selected: yup.number().default(0),
    skillExps: yup.array().of(apostleSkillExpSchema).default([]),
    status: apostleTicketStatusSchema,
    type: apostleTypeSchema,
    color: yup.string().default('')
  })
  .from('item_type', 'itemType')
  .from('random_factor', 'randomFactor')
  .noUnknown(true)

export const reservedApostleSchema: yup.SchemaOf<ReservedApostle> =
  apostleBaseSchema
    .concat(
      yup.object({
        cooldownNow: yup.boolean().default(false),
        reservedAt: yup.string().default(''),
        sameTypeSelected: yup.boolean().default(false),
        selected: yup.boolean().default(false),
        slotState: reservedApostleSlotStateSchema,
        sourceType: apostleSourceTypeSchema.default(ApostleSourceType.None),
        state: reservedApostleStateSchema
      })
    )
    .from('activeSkillsEXP', 'skillExps')
    .from('dungeonApostleID', 'id')
    .from('classType.class', 'class')
    .noUnknown(true)

export const apostleSkillSchema: yup.SchemaOf<ApostleSkill> = yup
  .object({
    description: yup.string().default(''),
    descriptionShort: yup.string().default(''),
    disabled: yup.boolean().default(false),
    image: yup.string().default(''),
    key: yup.string().default(''),
    level: yup.number().default(0),
    mana: yup
      .number()
      .transform(value => (isNaN(value) ? 0 : value))
      .default(0),
    name: yup.string().default(''),
    type: apostleSkillTypeSchema,
    class: apostleClassTypeSchema,
    uniqueKey: yup.string().default('')
  })
  .from('desc', 'description')
  .from('desc_short', 'descriptionShort')
  .from('name', 'name')
  .from('skill_id', 'key', true)
  .from('skill_id', 'uniqueKey')
  .from('skill_type', 'type')
  .from('skill_class', 'class')
  .noUnknown(true)

export const apostleMintSchema: yup.SchemaOf<ApostleMint> = yup
  .object({
    startAt: yup.string().default(''),
    endAt: yup.string().default(''),
    prices: yup
      .array()
      .of(
        currencyTokenLiteSchema
          .from('currencyCode', 'type')
          .from('price', 'amount')
          .noUnknown(true)
      )
      .default([]),
    tickets: yup.array().of(apostleTicketSchema).default([]),
    maxMintLimit: yup.number().default(0)
  })
  .from('mintInfo.priceList', 'prices')
  .from('mintInfo.beginTime', 'startAt')
  .from('mintInfo.endTime', 'endAt')
  .from('mintInfo.ticketID', 'tickets')
  .from('mintInfo.maxMintLimit', 'maxMintLimit')
  .noUnknown(true)

export const apostleLiteSchema: yup.SchemaOf<ApostleLite> = apostleBaseSchema
  .concat(
    yup.object({
      classId: apostleClassIdSchema,
      listingInfo: listingInfoSchema.nullable(true).default(null),
      listingStatus: listingStatusSchema,
      name: yup.string().default(''),
      redStatus: yup.boolean().default(false),
      reforgeCount: yup.number().default(0),
      status: apostleStatusSchema,
      statusText: yup.string().default('')
    })
  )
  .from('apostleID', 'id')
  .from('skills', 'skillExps')
  .from('class', 'classId')
  .from('metaKey.class', 'class')
  .from('ticketMint', 'sourceType')
  .from('nftListingStatus', 'listingStatus')
  .noUnknown(true)

export const apostleSchema: yup.SchemaOf<Apostle> = apostleLiteSchema
  .concat(
    yup.object({
      bookmarked: yup.boolean().default(false),
      level: yup.number().default(0),
      name: yup.string().default(''),
      owner: publicAccountSchema.nullable(true).default(null),
      stats: apostleStatsSchema
    })
  )
  .noUnknown(true)

export const apostleSignedSeasonMintSchema: yup.SchemaOf<ApostleSignedSeasonMint> =
  yup
    .object({
      seasonMintType: yup.number().default(0),
      walletAddress: yup.string().default(''),
      apostleClass: yup.array().of(yup.number()).default([]),
      apostleGene: yup.array().of(yup.string()).default([]),
      apostleIV: yup.array().of(yup.string()).default([]),
      timestamp: yup.number().default(0),
      isFreeMint: yup.array().of(yup.boolean()).default([]),
      freeMintTotalCount: yup.number().default(0),
      signature: yup.string().default(''),
      maxSlotMintCount: yup.number().default(0)
    })
    .noUnknown(true)

export const apostleReforgePreviewSchema: yup.SchemaOf<ApostleReforgePreview> =
  yup
    .object({
      beforeForge: apostleLiteSchema.nullable(),
      afterForge: apostleLiteSchema.nullable(),
      apostleId: yup.string().default('')
    })
    .from('apostleID', 'apostleId')
    .noUnknown(true)
