import {
  INFO_POPUP_TYPE,
  InfoPopup,
  NextImage,
  useAccountContext
} from '@apeiron/library'
import { Box, Button, styled, Typography } from '@mui/material'
import RenameSVG from '@public/icons/icon-rename.svg'
import PlayerSVG from '@src/components/account/menu/PlayerIcon'
import PlayerNameID from '@src/components/account/menu/PlayerNameID'
import QRCode from '@src/components/account/menu/QRCode'
import WalletBlueButton from '@src/components/account/menu/WalletBlueButton'
import ChangeNamePopup from '@src/components/account/settings/ChangeName'
import { FeatureFlagType } from '@src/constants/featureFlag'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import { padNumber } from '@src/util/tools/string'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Popup = styled(InfoPopup)`
  width: 350px;
`

const DescriptionContainer = styled(Box)`
  align-items: center;
  border-radius: 2px;
  border: 2px solid #525657;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
  padding: 16px 8px;
  width: 240px;
`

const QRButton = styled(Button)`
  font-size: 10px;
  width: 145px;
  height: 30px;
  border-radius: 0px;
  color: white;
  background-color: #343637;
  text-transform: none;
`

const QRPopup = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const QRContainer = styled(Box)`
  margin: 50px 168px;
`

const StyledPlayerNameID = styled(PlayerNameID)`
  margin-bottom: 5px;
`

const PlayerInfoContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
`

const PlayerNameRow = styled(Box)`
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 6px;
  width: 100%;
`

const PlayerName = styled(Typography)`
  min-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const PlayerID = styled(Typography)`
  margin-top: 2px;
  color: #b9bbbe;
  white-space: nowrap;
`

const PlayerEmail = styled(Typography)`
  color: #666666;
  text-align: center;
  width: 100%;
  overflow-wrap: break-word;
`

const EditButton = styled(Button)`
  padding: 6px 0px;
  min-width: 18px;
`

const StyledNextImage = styled(NextImage)`
  width: 18px;
  aspect-ratio: 1;
`

const StyledWalletBlueButton = styled(WalletBlueButton)`
  width: 145px;
  height: 30px;
`

const MyAccountDescription: FC = () => {
  const { t } = useTranslation()

  const [isQROpended, setIsQROpened] = useState(false)

  const [isChangeNameOpened, setIsChangeNameOpened] = useState(false)

  const { account } = useAccountContext()

  const handleOnEdit = useCallback(() => {
    setIsChangeNameOpened(true)
  }, [])

  const handleOnChangeNameClose = useCallback(() => {
    setIsChangeNameOpened(false)
  }, [])

  const handleOnQRCodeOpen = useCallback(() => {
    setIsQROpened(true)
  }, [])

  const handleOnQrCodeClose = useCallback(() => {
    setIsQROpened(false)
  }, [])

  return (
    <DescriptionContainer>
      <PlayerSVG />
      <PlayerInfoContainer>
        <PlayerNameRow>
          <PlayerName>{account.name}</PlayerName>
          <PlayerID># {padNumber(account.tag, 4)}</PlayerID>
          <EditButton onClick={handleOnEdit}>
            <StyledNextImage src={RenameSVG} />
          </EditButton>
        </PlayerNameRow>
        <PlayerEmail>{account.email}</PlayerEmail>
      </PlayerInfoContainer>
      <ChangeNamePopup
        open={isChangeNameOpened}
        onClose={handleOnChangeNameClose}
      />
      {featureAllowed(FeatureFlagType.ShowAccountQRCode) && (
        <QRButton onClick={handleOnQRCodeOpen}>
          {t('account_menu.show_qrcode')}
        </QRButton>
      )}
      <StyledWalletBlueButton />
      <Popup
        type={INFO_POPUP_TYPE.BLACK}
        open={isQROpended}
        onClose={handleOnQrCodeClose}
      >
        <QRPopup>
          <QRContainer>
            <StyledPlayerNameID />
            <QRCode />
          </QRContainer>
        </QRPopup>
      </Popup>
    </DescriptionContainer>
  )
}

export default MyAccountDescription
