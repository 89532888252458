import { createStrictContext } from '@apeiron/library'
import { PRELOAD_KEY } from '@src/constants/preload'
import { usePreloadContext } from '@src/contexts/share/PreloadContext'
import useGetFrontendConfig from '@src/hooks/graphql/useGetFrontendConfig'
import { FrontendConfig } from '@src/types/config'
import { ReactChild, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

type IFrontendConfigContext = {
  config: FrontendConfig | null
}

const [ContextProvider, useFrontendConfigContext] =
  createStrictContext<IFrontendConfigContext>('FrontendConfig')

export { useFrontendConfigContext }

export const FrontendConfigProvider = (props: Props) => {
  const { children } = props

  const [config, setConfig] = useState<FrontendConfig | null>(null)

  const { preloadValue } = usePreloadContext()

  const { fetch: fetchConfig } = useGetFrontendConfig()

  useAsyncEffect(async (isActive: () => boolean) => {
    const value = await preloadValue(PRELOAD_KEY.FRONTEND_CONFIG, fetchConfig)

    if (!isActive()) {
      return
    }

    setConfig(value)
  }, [])

  return <ContextProvider value={{ config }}>{children}</ContextProvider>
}

type Props = {
  children: ReactChild | ReactChild[]
}
