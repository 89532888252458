import { NextImage, SkeletonBox, SsoProviderType } from '@apeiron/library'
import { ButtonBase, styled } from '@mui/material'
import useSsoAuth from '@src/hooks/apeiron/account/useSsoAuth'
import useFetchSsoJson from '@src/hooks/json/useFetchSsoJson'
import * as R from 'ramda'
import { FC, useCallback, useEffect } from 'react'

const Container = styled(SkeletonBox)`
  background-color: #0c0c0c;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 12px 20px;
  position: relative;
  width: 100%;
`

const CircleAuthButton = styled(ButtonBase)`
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
`

const Logo = styled(NextImage)`
  width: 100%;
  height: 100%;
`

const CommunityAuthSection: FC<Props> = (props: Props) => {
  const { className } = props

  const { login } = useSsoAuth()

  const { data: providers, fetchJson } = useFetchSsoJson()

  useEffect(() => {
    fetchJson()
  }, [])

  const handleClick = useCallback(
    async (type: SsoProviderType) => {
      switch (type) {
        case SsoProviderType.SKY_MAVIS:
        case SsoProviderType.DISCORD:
          await login(type)

          break

        default:
          break
      }
    },
    [login]
  )

  const loading = R.isEmpty(providers)

  return (
    <Container className={className} loading={loading}>
      {providers.map(provider => (
        <CircleAuthButton
          key={provider.name}
          onClick={() => {
            handleClick(provider.type)
          }}
        >
          <Logo src={provider.icon.circular} />
        </CircleAuthButton>
      ))}
    </Container>
  )
}

type Props = {
  className?: string
}

export default CommunityAuthSection
