import { useMessageContext } from '@apeiron/library'
import { Alert, AlertColor, AlertProps, Snackbar, styled } from '@mui/material'
import { DEFAULT_SNACKBAR_SHOW_DURATION } from '@src/constants/layout'
import * as R from 'ramda'
import { FC, forwardRef, useCallback, useEffect, useState } from 'react'

const StyledSnackbar = styled(Snackbar)`
  bottom: unset;
  left: 50%;
  right: unset;
  top: 8px;
  transform: translateX(-50%);
  width: unset;
`

const MuiAlert = forwardRef<HTMLDivElement, AlertProps>(function MuiAlert(
  props,
  ref
) {
  return <Alert ref={ref} {...props} />
})

const MuiSnackbar: FC<Props> = (props: Props) => {
  const { className } = props

  const { snackInfo, setSnackInfo } = useMessageContext()

  const [message, setMessage] = useState<string>('')

  const [serverity, setServerity] = useState<AlertColor>('success')

  const handleOnClose = useCallback(() => {
    setSnackInfo(null)
  }, [setSnackInfo])

  useEffect(() => {
    if (R.isNotNil(snackInfo)) {
      setMessage(snackInfo.message)
      setServerity(snackInfo.serverity)
    }
  }, [snackInfo])

  return (
    <StyledSnackbar
      className={className}
      open={R.isNotNil(snackInfo)}
      autoHideDuration={DEFAULT_SNACKBAR_SHOW_DURATION}
      onClose={handleOnClose}
    >
      <MuiAlert onClose={handleOnClose} variant='filled' severity={serverity}>
        {message}
      </MuiAlert>
    </StyledSnackbar>
  )
}

type Props = {
  className?: string
}

export default MuiSnackbar
