// enum -
export enum PRELOAD_KEY {
  BREED_IS_PRIMEVAL = 0, // contract
  FRONTEND_CONFIG, // graphql
  APOSTLE_TICKETS_DATA, // json
  APP_CONNECTIONS, // json
  ASTRONOMICAL_FILTERS, // json
  BATTLE_DEMO_CONFIG, // json
  BATTLE_DEMO_FRAGMENTS, // json
  BATTLE_DEMO_ADVERTISEMENT, // json
  CONJUNCT_COST, // json
  CONSTELLATIONS_DATA, // json
  ORBIT_FILTERS, // json
  PLANET_FILTERS, // json
  PLANET_TYPE_GROUPS_DATA, // json
  PRIMEVAL_LEGACY_DATA, // json
  PLANET_SKILLS_DATA, // json
  STAR_FILTERS, // json
  ACTIVITY_FILTER, // json
  APOSTLE_FILTERS, // json
  APOSTLE_SKILLS, // json
  APOSTLE_STATS, // json
  APOSTLE_CLASS_LIST, // json
  SSO_PROVIDERS, // json
  EXPEDITION_REWARD_QUANTITY, // json
  SKILL_GLOSSARY, // json
  BOUNTY_APOSTLE_FILTER // json
}
