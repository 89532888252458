import 'swiper/css/effect-fade'

import {
  ChainAddressType,
  INFO_POPUP_TYPE,
  InfoPopup,
  isIosOrAndroid,
  NextImage,
  useAccountContext,
  WalletType
} from '@apeiron/library'
import { Box, styled, useMediaQuery } from '@mui/material'
import LogoPNG from '@public/icons/icon-logo.png'
import BackgroundPNG from '@public/images/background-login-popup.png'
import BackgroundMobilePNG from '@public/images/image-bind-email-mobile.jpg'
import LoginGameClientSection from '@src/components/account/login/LoginGameClientSection'
import RoninLoginSection from '@src/components/account/login/RoninLoginSection'
import RootLoginSection from '@src/components/account/login/RootLoginSection'
import SelectWalletSection from '@src/components/account/login/SelectWalletSection'
import SignupSection from '@src/components/account/login/SignupSection'
import ApeironCircularBackButton from '@src/components/share/apeiron/ApeironCircularBackButton'
import ApeironCloseButton from '@src/components/share/apeiron/ApeironCloseButton'
import { LOGIN_SLIDE_STATE } from '@src/constants/login'
import { MobileWalletSnackBarMode } from '@src/constants/wallet'
import { useRoninWalletSnackBarContext } from '@src/contexts/wallet/RoninWalletSnackbarContext'
import theme from '@src/styles/theme'
import * as R from 'ramda'
import { FC, useCallback, useRef, useState } from 'react'
import { Controller, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'

const StyledInfoPopup = styled(InfoPopup)`
  ${props => props.theme.breakpoints.up('res1024')} {
    height: unset;
    max-width: 1120px;
    width: 95%;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    height: 100%;
    width: 100vw;
    max-width: 700px;
    background-color: #1c1f25;
  }
  .Custom-Content {
    position: relative;
    display: flex;
    overflow-y: auto;

    ${props => props.theme.breakpoints.down('res1024')} {
      flex-direction: column;
    }
  }
`

const StyledApeironCircularBackButton = styled(ApeironCircularBackButton)`
  position: absolute;
  top: 10px;
  left: 20px;
`

const PopupBackground = styled(Box)`
  display: flex;
  z-index: 7;
  ${props => props.theme.breakpoints.up('res1024')} {
    height: min(790px, calc(100vh - 40px));
    width: 1120px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #262829;
    box-shadow: 0px 8px 32.4316px rgba(0, 0, 0, 0.65);
    position: fixed;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    flex-direction: column;
    width: 100%;
    top: 50px;
    background-color: #1c1f25;
  }
`

const StyledSwiper = styled(Swiper)`
  & .swiper-wrapper,
  .swiper-slide {
    ${props => props.theme.breakpoints.up('res1024')} {
      width: 560px;
      height: min(790px, calc(100vh - 40px)) !important;
    }
    ${props => props.theme.breakpoints.down('res1024')} {
      width: 100%;
    }
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100%;
  }
`

const CrossButton = styled(ApeironCloseButton)`
  top: 20px;
  right: 20px;
  position: absolute;
  z-index: 8;
`

const MainContainer = styled(Box)`
  position: relative;
  ${props => props.theme.breakpoints.up('res1024')} {
    background-image: url(${BackgroundPNG.src});
    background-size: 100%;
    width: 560px;
    height: 100%;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    background-image: url(${BackgroundMobilePNG.src});
    background-size: 100%;
    width: 100%;
    aspect-ratio: 2.5;
    transform: scaleX(-1);
    background-repeat: no-repeat;
    margin-bottom: -60px;
  }
`

const MobileHeaderGradient = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #1c1f25 80%,
    #1c1f25 100%
  );
`

const StyledLoginGameClientSection = styled(LoginGameClientSection)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 40px;
`

const Logo = styled(NextImage)`
  position: absolute;
  aspect-ratio: 4.15;
  height: 25%;
  bottom: 0;
  top: 0;
  right: 10%;
  margin: auto;
  transform: scaleX(-1);
`

const SwiperContainer = styled(Box)`
  position: relative;
  flex: 1;
  height: 100%;
`

const Content: FC = () => {
  const swiperRef = useRef<SwiperType | undefined>()

  const [showBackButton, setShowBackButton] = useState<boolean>(false)

  const { setShowLoginPopup } = useAccountContext()

  const { loginByWallet } = useAccountContext()

  const { openSnackBar } = useRoninWalletSnackBarContext()

  const mobile = useMediaQuery(theme.breakpoints.down('res1024'))

  const handleOnBack = useCallback(() => {
    if (R.isNotNil(swiperRef.current)) {
      swiperRef.current.slideTo(LOGIN_SLIDE_STATE.EMAIL_LOGIN)
    }
  }, [])

  const handleOnClose = useCallback(() => {
    setShowLoginPopup(false)

    if (R.isNotNil(swiperRef.current)) {
      swiperRef.current.slideTo(LOGIN_SLIDE_STATE.EMAIL_LOGIN)
    }
  }, [setShowLoginPopup])

  const handleOnSwiper = useCallback((swiperType: SwiperType) => {
    swiperRef.current = swiperType
  }, [])

  const handleOnActiveIndexChange = useCallback((swiperType: SwiperType) => {
    setShowBackButton(swiperType.activeIndex !== LOGIN_SLIDE_STATE.EMAIL_LOGIN)
  }, [])

  const handleOnRoninLogin = useCallback(async () => {
    if (isIosOrAndroid()) {
      if (!window.ronin) {
        openSnackBar(MobileWalletSnackBarMode.Connect)
        await loginByWallet(WalletType.RoninMobile, ChainAddressType.RONIN)
      } else {
        await loginByWallet(WalletType.Ronin, ChainAddressType.RONIN)
      }

      return
    }

    if (R.isNotNil(swiperRef.current)) {
      swiperRef.current.slideTo(LOGIN_SLIDE_STATE.RONIN_WALLET_LOGIN)
    }
  }, [loginByWallet, openSnackBar])

  return (
    <PopupBackground>
      {mobile ? (
        <MainContainer>
          <MobileHeaderGradient />
          <Logo src={LogoPNG} />
        </MainContainer>
      ) : (
        <MainContainer>
          <StyledLoginGameClientSection />
        </MainContainer>
      )}
      <SwiperContainer>
        <StyledSwiper
          allowTouchMove={false}
          autoHeight
          direction='horizontal'
          modules={[EffectFade, Controller]}
          slidesPerView={1}
          spaceBetween={0}
          onSwiper={handleOnSwiper}
          onActiveIndexChange={handleOnActiveIndexChange}
        >
          <SwiperSlide>
            <RootLoginSection onRoninLogin={handleOnRoninLogin} />
          </SwiperSlide>
          <SwiperSlide>
            <SelectWalletSection onRoninLoginClick={handleOnRoninLogin} />
          </SwiperSlide>
          <SwiperSlide>
            {({ isActive }) => <RoninLoginSection isActive={isActive} />}
          </SwiperSlide>
          <SwiperSlide>
            <SignupSection />
          </SwiperSlide>
        </StyledSwiper>
        {showBackButton && (
          <StyledApeironCircularBackButton onClick={handleOnBack} />
        )}
        {!mobile && <CrossButton onClick={handleOnClose} />}
      </SwiperContainer>
    </PopupBackground>
  )
}

const LoginView: FC<Props> = () => {
  const { showLoginPopup } = useAccountContext()

  const { setShowLoginPopup } = useAccountContext()

  const handleOnClose = useCallback(() => {
    setShowLoginPopup(false)
  }, [setShowLoginPopup])

  return (
    <StyledInfoPopup
      keepMounted={false}
      type={INFO_POPUP_TYPE.BLACK}
      open={showLoginPopup}
      onClose={handleOnClose}
    >
      <Content />
    </StyledInfoPopup>
  )
}

type Props = {}

export default LoginView
