import { FrontendBountyConfig } from '@src/types/config'

const useMakeBountyConfig = () => {
  const makeBountyConfig = (frontendConfig: any): FrontendBountyConfig => {
    return {
      apostle: {
        supportedCurrencies:
          frontendConfig.bounty.apostle.support_post_currency_code
      }
    }
  }

  return {
    makeBountyConfig
  }
}

export default useMakeBountyConfig
