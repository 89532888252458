import {
  ChainAddressType,
  createStrictContext,
  isIosOrAndroid,
  useAccountContext,
  WalletType
} from '@apeiron/library'
import { SnackbarCloseReason } from '@mui/material'
import OpenRoninWalletAppSnackBar from '@src/components/wallet/OpenRoninWalletAppSnackBar'
import { MobileWalletSnackBarMode } from '@src/constants/wallet'
import useWalletConnect from '@src/hooks/contract/useWalletConnect'
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

type RoninWalletSnackBarContext = {
  openSnackBar: (mode: MobileWalletSnackBarMode) => void
}

const [ContextProvider, useRoninWalletSnackBarContext] =
  createStrictContext<RoninWalletSnackBarContext>('RoninWalletSnackBar')

export { useRoninWalletSnackBarContext }

export const RoninWalletSnackbarProvider = (props: Props) => {
  const { children } = props

  const lastMode = useRef(MobileWalletSnackBarMode.None)

  const { openRoninMobileWallet, roninQRCode, getDeepLink } = useWalletConnect()

  const { loginByWallet, isLoggedIn, isWalletConnected } = useAccountContext()

  const [mode, setMode] = useState<MobileWalletSnackBarMode>(
    MobileWalletSnackBarMode.None
  )

  const setModeAndSaveLast = (mode: MobileWalletSnackBarMode) => {
    lastMode.current = mode
    setMode(mode)
  }

  const [isMobileAppSnackBarOpen, setIsMobileAppSnackBarOpen] =
    useState<boolean>(false)

  const openSnackBar = useCallback((mode: MobileWalletSnackBarMode) => {
    setIsMobileAppSnackBarOpen(true)

    setModeAndSaveLast(mode)
  }, [])

  const handleClose = useCallback(
    (_: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return
      }

      setIsMobileAppSnackBarOpen(false)
    },
    []
  )

  useEffect(() => {
    const inLoginProcess = lastMode.current === MobileWalletSnackBarMode.Connect

    if (
      isWalletConnected &&
      !isLoggedIn &&
      isIosOrAndroid() &&
      inLoginProcess
    ) {
      setModeAndSaveLast(MobileWalletSnackBarMode.SignMessage)
      setIsMobileAppSnackBarOpen(true)
    }
  }, [isWalletConnected, isLoggedIn])

  const handleOpenApp = useCallback(async () => {
    if (
      mode === MobileWalletSnackBarMode.None ||
      mode === MobileWalletSnackBarMode.SignTransaction
    ) {
      openRoninMobileWallet()
      setIsMobileAppSnackBarOpen(false)
    } else if (roninQRCode && !isWalletConnected) {
      openRoninMobileWallet(getDeepLink())
      setIsMobileAppSnackBarOpen(false)
    } else if (isWalletConnected && !isLoggedIn) {
      await loginByWallet(WalletType.RoninMobile, ChainAddressType.RONIN)
      setIsMobileAppSnackBarOpen(false)
    }
  }, [
    mode,
    roninQRCode,
    isWalletConnected,
    isLoggedIn,
    openRoninMobileWallet,
    getDeepLink,
    loginByWallet
  ])

  return (
    <ContextProvider
      value={{
        openSnackBar
      }}
    >
      {children}
      <OpenRoninWalletAppSnackBar
        open={isMobileAppSnackBarOpen}
        onClose={handleClose}
        onOpenApp={handleOpenApp}
        mode={mode}
        loading={mode === MobileWalletSnackBarMode.Connect && !roninQRCode}
      />
    </ContextProvider>
  )
}

type Props = {
  children: ReactNode
}
