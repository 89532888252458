// this enum align with backend
export enum NotificationDataType {
  DailyCheckIn = 'DailyCheckIn',
  Expedition = 'Expedition'
}

export enum NotifyType {
  // don't move top bar position
  TOPBAR_BATTLE_DEMO = 0,
  TOPBAR_EMPORIUM,
  TOPBAR_SANCTUARY,
  TOPBAR_GODIVERSE,
  TOPBAR_EXPEDITION,
  SHORTCUT_DAILY_CHECKIN
}
