import {
  AdeptusCodeInput,
  AdeptusInputMode,
  ApeironBlueButton,
  ConfirmPasswordTextField,
  EmailOTPSender,
  OTPCodeTextField,
  OTPType,
  PasswordTextField,
  useAccountContext
} from '@apeiron/library'
import DoneIcon from '@mui/icons-material/Done'
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography
} from '@mui/material'
import CommunityAuthSection from '@src/components/account/login/CommunityAuthSection'
import LoginAgreement from '@src/components/account/login/LoginAgreement'
import { FC, useCallback, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

const MainContainer = styled(Box)`
  background-color: #1c1f25;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px;
  ${props => props.theme.breakpoints.up('res1024')} {
    width: 560px;
    overflow-y: auto;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100%;
  }
`

const Title = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  margin-top: 40px;
`

const Form = styled('form')`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px;
  width: 100%;
`

const Label = styled(Typography)`
  align-items: flex-start;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  justify-content: flex-start;
  white-space: pre-line;
  strong {
    color: #676869;
    font-size: 12px;
    font-weight: 300;
  }
`

const StyledCheckbox = styled(Checkbox)`
  > .MuiSvgIcon-root {
    background-color: black;
    border-radius: 4px;
  }
  > .Mui-checked {
    color: black;
    :before {
      background-image: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"+
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")";
    }
  }
`

const Separator = styled(Box)`
  border-top: 1px dashed #979797;
  margin: 16px 0px;
  width: 100%;
`

const RegisterBtn = styled(ApeironBlueButton)`
  border-radius: 8px;
  height: 42px;
  margin: 10px 0;
  width: 100%;
`

const CodeInput = styled(AdeptusCodeInput)`
  margin-bottom: 16px;
  width: 100%;
`

const SignupSection: FC<Props> = (props: Props) => {
  const { className } = props

  const { t } = useTranslation()

  const { signUp } = useAccountContext()

  const { control, trigger, handleSubmit } = useForm({
    mode: 'onChange'
  })

  const password = useWatch({ name: 'password', control })

  const [checked, setChecked] = useState(false)

  const handleOnChange = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  const doSubmit = useCallback(
    async (data: Record<string, any>) => {
      const { email, otpCode, password } = data

      await signUp({ email, otpCode, password, marketingMailOption: checked })
    },
    [checked, signUp]
  )

  return (
    <MainContainer className={className}>
      <Title>{t('login.signup.title')}</Title>
      <Form onSubmit={handleSubmit(doSubmit)}>
        <EmailOTPSender
          name='email'
          control={control}
          otpType={OTPType.AccountRegister}
        />
        <OTPCodeTextField name='otpCode' control={control} />
        <PasswordTextField
          autoComplete='new-password'
          control={control}
          name='password'
        />
        <ConfirmPasswordTextField
          autoComplete='new-password'
          control={control}
          name='confirmPassword'
          password={password}
          trigger={trigger}
        />
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={checked}
              onChange={handleOnChange}
              checkedIcon={<DoneIcon sx={{ color: 'white' }} />}
            />
          }
          label={
            <Label>
              <Trans>
                {t(
                  'account_setting.notification_settings_label.event_full_sentence'
                )}
              </Trans>
            </Label>
          }
        />
        <RegisterBtn type='submit'>{t('login.signup.register')}</RegisterBtn>
      </Form>
      <Separator />
      <CodeInput showFoot={false} mode={AdeptusInputMode.AccountLogin} />
      <CommunityAuthSection />
      <LoginAgreement />
    </MainContainer>
  )
}

type Props = {
  className?: string
}

export default SignupSection
