import { createStrictContext } from '@apeiron/library'
import { ElementType } from '@src/constants/planet'
import useFetchPlanetFilterJson from '@src/hooks/json/useFetchPlanetFilterJson'
import { Element } from '@src/types/planet'
import * as R from 'ramda'
import { FC, ReactChild, useCallback, useState } from 'react'
import useAsyncEffect from 'use-async-effect'

const [ContextProvider, useElementContext] =
  createStrictContext<ElementContextValue>('Element')

export { useElementContext }
export const ElementProvider: FC<Props> = (props: Props) => {
  const { children } = props

  const { fetchJson: fetchFilterJson } = useFetchPlanetFilterJson()

  const [elements, setElements] = useState<Element[]>([])

  const [elementTypes, setElementTypes] = useState<ElementType[]>([])

  const extractElement = useCallback(
    (type: ElementType): Element | undefined => {
      return R.find((element: Element) => element.value === type, elements)
    },
    [elements]
  )

  useAsyncEffect(async (isActive: () => boolean) => {
    const filterSetting = await fetchFilterJson()

    if (!isActive()) {
      return
    }

    const result = R.propOr([], 'planetFilterElement', filterSetting) as any[]

    setElements(result)
    setElementTypes(R.pluck('value', result) as ElementType[])
  }, [])

  return (
    <ContextProvider value={{ elements, elementTypes, extractElement }}>
      {children}
    </ContextProvider>
  )
}

export type ElementContextValue = {
  elements: Element[]
  elementTypes: ElementType[]
  extractElement: (type: ElementType) => Element | undefined
}

export type Props = {
  children: ReactChild | ReactChild[]
}
