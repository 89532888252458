import { MayNull } from '@apeiron/library'
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

const useRecursiveCalling = (props: Props): Hook => {
  const { active = true, duration, callback } = props

  const ref = useRef<RecursiveCallRef>(null)

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const secondRef = useRef<number>(duration)

  const [remainSecond, setRemainSecond] = useState<number>(duration)

  useEffect(() => {
    if (active) {
      const tick = () => {
        secondRef.current--
        if (secondRef.current === 0) {
          callback(ref.current)
          secondRef.current = duration
        }

        setRemainSecond(secondRef.current)
        timeoutRef.current = setTimeout(tick, 1000)
      }

      callback(ref.current)
      tick()
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [active, duration])

  const reset = useCallback(() => {
    secondRef.current = duration
    setRemainSecond(secondRef.current)
  }, [duration])

  useImperativeHandle(
    ref,
    () => {
      return {
        reset
      }
    },
    [reset]
  )

  return {
    remainSecond
  }
}

export type RecursiveCallRef = {
  reset: () => void
}

type Callback = (ref: MayNull<RecursiveCallRef>) => void

type Props = {
  active?: boolean
  duration: number // in second
  callback: Callback
}

type Hook = {
  remainSecond: number
}

export default useRecursiveCalling
