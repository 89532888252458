import { useLazyQuery } from '@apollo/client'
import useMakeApsotleMintConfig from '@src/deserialize/hook/useMakeApsotleMintConfig'
import useMakeBountyConfig from '@src/deserialize/hook/useMakeBountyConfig'
import { frontendConfigSchema } from '@src/deserialize/yup/config'
import graphql from '@src/graphql/query/getFrontendConfig'
import { FrontendConfig } from '@src/types/config'
import * as R from 'ramda'
import { useCallback, useState } from 'react'

const useGetFrontendConfig = (): Hook => {
  const [query, { loading }] = useLazyQuery(graphql)

  const [data, setData] = useState<Response>(null)

  const { makeApostleMintConfig } = useMakeApsotleMintConfig()

  const { makeBountyConfig } = useMakeBountyConfig()

  const fetch = useCallback(async () => {
    const response = await query()

    const result = frontendConfigSchema.cast(
      R.pathOr({}, ['data', 'getSystemConfig', 0], response)
    ) as Response

    if (!result) {
      return null
    }

    result.apostleMintConfig = makeApostleMintConfig(
      response.data.getSystemConfig[0].frontendConfig
    )

    result.bounty = makeBountyConfig(
      response.data.getSystemConfig[0].frontendConfig
    )

    setData(result)

    return result
  }, [makeApostleMintConfig, makeBountyConfig, query])

  return {
    data,
    loading,
    fetch
  }
}

type Response = FrontendConfig | null

type Hook = {
  data: Response
  loading: boolean
  fetch: () => Promise<Response>
}

export default useGetFrontendConfig
