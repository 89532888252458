import {
  ApeironBlueButton,
  CustomError,
  EmailTextField,
  ERROR_CODE,
  ForgotPasswordButton,
  PasswordTextField,
  useAccountContext,
  useGeetest4Context,
  useMessageContext
} from '@apeiron/library'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Checkbox, styled, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as R from 'ramda'
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const MainContainer = styled(Box)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
`

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const RememberBox = styled(Checkbox)`
  > .MuiSvgIcon-root {
    background-color: black;
  }
  > .Mui-checked {
    color: black;
    :before {
      background-image: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"+
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")";
    }
  }
  ${props => props.theme.breakpoints.down('res768')} {
    margin-left: 10px;
  }
`

const RememberMeContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LoginValidation = styled(Typography)`
  color: #ff0000;
  width: 100%;
  height: 20px;
`

const LoginValidationPlaceholder = styled(Box)`
  width: 100%;
  height: 20px;
`

const SignInButton = styled(ApeironBlueButton)`
  margin-left: auto;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  :hover {
    background-color: #ffffffa8;
  }
  color: black;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
`

const EmailLoginComponent: FC<Props> = (props: Props) => {
  const { className } = props

  const { t } = useTranslation()

  const [wrongAccount, setWrongAccount] = useState(false)

  const [checked, setChecked] = useState(true)

  const { doGeetest } = useGeetest4Context()

  const { loginByEmail } = useAccountContext()

  const { setSnackInfo } = useMessageContext()

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange'
  })

  const handleOnFieldChange = useCallback(() => {
    setWrongAccount(false)
  }, [])

  const handleOnCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked)
    },
    []
  )

  const handleOnLogin = useCallback(
    async (data: Record<string, any>) => {
      const { email, password } = data

      doGeetest(async result => {
        try {
          const account = await loginByEmail({
            email,
            password,
            geeTest: result
          })

          if (R.isNil(account)) {
            throw new CustomError(
              'cannot get account response',
              ERROR_CODE.LOGIN.CANNOT_GET_ACCOUNT_RESPONSE
            )
          }

          setSnackInfo({
            message: t('login.general.login_success'),
            serverity: 'success'
          })
        } catch (error) {
          setWrongAccount(true)
        }
      })
    },
    [doGeetest, loginByEmail, setSnackInfo, t]
  )

  useEffect(() => {
    // reset form when this slide inactive
    reset({
      email: '',
      password: ''
    })
    setChecked(false)
    setWrongAccount(false)
  }, [reset])

  return (
    <MainContainer className={className}>
      <Form onSubmit={handleSubmit(handleOnLogin)}>
        <EmailTextField
          control={control}
          enableValidation
          name={'email'}
          onChange={handleOnFieldChange}
        />
        <PasswordTextField
          control={control}
          enableValidation
          name={'password'}
          onChange={handleOnFieldChange}
        />
        <RememberMeContainer>
          <FormControlLabel
            control={
              <RememberBox
                checked={checked}
                onChange={handleOnCheckboxChange}
                checkedIcon={<DoneIcon sx={{ color: 'white' }} />}
              />
            }
            label={t('login.email.rememberBox')}
          />
          <ForgotPasswordButton />
        </RememberMeContainer>

        {wrongAccount ? (
          <LoginValidation>
            {t('login.email.validation.wrong_input')}
          </LoginValidation>
        ) : (
          <LoginValidationPlaceholder />
        )}
        <SignInButton
          disabled={!formState.isDirty || !formState.isValid}
          type='submit'
        >
          {t('login.email.button.signIn')}
        </SignInButton>
      </Form>
    </MainContainer>
  )
}

type Props = {
  className?: string
}

export default EmailLoginComponent
