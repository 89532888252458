import { MuiLoading } from '@apeiron/library'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { styled } from '@mui/material'
import { FC } from 'react'

const StyledLoadingButton = styled(LoadingButton)<StyledLoadingButtonProps>`
  ${props => props.loading && 'filter: grayscale(80%);'}

  background-color: #005bd7;
  border-radius: 3px;
  color: white;
  font-weight: 400;
  text-transform: unset;
  transition: filter 0.3s ease-in-out;
  :hover {
    background-color: #005bd7;
    filter: brightness(1.1);
  }
  :focus {
    background-color: #005bd7;
  }
  &.Mui-disabled {
    background-color: #ffffff11;
    border: 1px solid #ffffff33;
  }
  &:not(.MuiLoadingButton-loading).Mui-disabled {
    color: #ffffff33;
  }
`

const StyledMuiLoading = styled(MuiLoading)`
  color: #e9e9ea;
`

const ApeironLoadingButton: FC<LoadingButtonProps> = (
  props: LoadingButtonProps
) => {
  return (
    <StyledLoadingButton
      loading={props.loading}
      loadingIndicator={<StyledMuiLoading loading size={24} />}
      {...props}
    />
  )
}

type StyledLoadingButtonProps = {
  loading?: boolean
}

export default ApeironLoadingButton
