import {
  AdeptusSuccessPopup,
  ApeironCookieConsent,
  MessagePopup,
  noForwardProps,
  useGoogleTagManager,
  useLanguageQuery
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import BackgroundJPG from '@public/images/background-battle-demo.jpg'
import BackgroundRepeatPNG from '@public/images/background-repeat.png'
import BackgroundTopPNG from '@public/images/background-top.png'
import AccountHeader from '@src/components/account/header'
import LoginView from '@src/components/account/login/LoginView'
import PrmiaryNavBar from '@src/components/navigationBar/primary/PrimaryNavBar'
import SeconrdaryNavBar from '@src/components/navigationBar/secondary/SecondaryNavBar'
import OgData from '@src/components/ogData/OgData'
import ApeironBindWalletPopup from '@src/components/share/apeiron/ApeironBindWalletPopup'
import ApeironErrorBoundary from '@src/components/share/apeiron/ApeironErrorBoundary'
import ApeironNoNetworkSnackbar from '@src/components/share/apeiron/ApeironNoNetworkSnackbar'
import CheckMaintenance from '@src/components/share/generic/CheckMaintenance'
import EnoughRoleOnly from '@src/components/share/generic/EnoughRoleOnly'
import MuiSnackbar from '@src/components/share/mui/Snackbar'
import NextHead from '@src/components/share/next/Head'
import GeetestScript from '@src/components/share/next/script/GeetestScript'
import GoogleTagManagerScript from '@src/components/share/next/script/GoogleTagManagerScript'
import NewRelicScript from '@src/components/share/next/script/NewRelicScript'
import StructuredData from '@src/components/structuredData/SharedStructuredData'
import WalletWarningPopup from '@src/components/wallet/WalletWarningPopup'
import { NAV_BAR_CONFIG } from '@src/constants/layout'
import { ROLE_ACCESS } from '@src/constants/route'
import usePath from '@src/hooks/common/router/usePath'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import { FC, ReactNode } from 'react'

const Content = styled(Box, noForwardProps)<MaxWidthProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${NAV_BAR_CONFIG.primary.height}px;
  ${props =>
    props.__useMaxWidth
      ? `
        background-image: url(${BackgroundJPG.src});
        background-position: center;
        background-size: cover;
      `
      : `
        background: url(${BackgroundTopPNG.src}) top center repeat-x, url(${BackgroundRepeatPNG.src}) top center repeat;
      `}
`

const MainContent = styled(Box, noForwardProps)<MaxWidthProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: clip;
  position: relative;
  width: 100%;
  ${props =>
    props.__useMaxWidth
      ? `
        box-shadow: 0px -18px 20px #111;
        margin: 0 auto;
        max-width: 1920px;
      `
      : ''}
`

const Layout: FC<Props> = (props: Props) => {
  const { children } = props

  const { boundMaxWidth, showAccountHeader, showNavigationBar } = usePath()

  const { gtmAcceptConsent } = useGoogleTagManager()

  const router = useRouter()

  useLanguageQuery(router)

  return (
    <>
      <NextNProgress color='#ffb752' height={1} showOnShallow={false} />
      <LoginView />
      <NextHead />
      <OgData />
      <Content __useMaxWidth={boundMaxWidth}>
        {showAccountHeader && <AccountHeader />}
        <SeconrdaryNavBar />
        <MainContent __useMaxWidth={boundMaxWidth}>
          <GeetestScript />
          <GoogleTagManagerScript />
          <NewRelicScript />
          <EnoughRoleOnly restriction={ROLE_ACCESS}>
            <CheckMaintenance>
              <ApeironErrorBoundary>
                <StructuredData />
                {children}
                <ApeironCookieConsent onAccept={gtmAcceptConsent} />
              </ApeironErrorBoundary>
            </CheckMaintenance>
          </EnoughRoleOnly>
        </MainContent>
      </Content>
      {showNavigationBar && <PrmiaryNavBar />}
      <AdeptusSuccessPopup />
      <WalletWarningPopup />
      <MessagePopup />
      <MuiSnackbar />
      <ApeironNoNetworkSnackbar />
      <ApeironBindWalletPopup />
    </>
  )
}

type MaxWidthProps = {
  __useMaxWidth?: boolean
}

type Props = {
  children: ReactNode
}

export default Layout
