import {
  AnimatedBox,
  getAccessToken,
  MuiLoading,
  Role,
  useAccountContext
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import { RESTRICTED_MODE } from '@src/constants/layout'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const LoadingContainer = styled(Box)`
  align-items: center;
  background-color: #26282a;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1;
`

const BlockContainer = styled(AnimatedBox)`
  align-items: center;
  background: linear-gradient(90deg, #333333 0%, #343434 35%, #445566 100%);
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
`

const EnoughRoleOnly: FC<Props> = (props: Props) => {
  const { children, restriction } = props

  const { t } = useTranslation()

  const [restricted, setRestricted] = useState<RESTRICTED_MODE>(
    RESTRICTED_MODE.WAITING
  )

  const router = useRouter()

  const { account, isLoggedIn, setShowLoginPopup } = useAccountContext()

  const handleOnRouteChange = useCallback(
    (asPath: string) => {
      const extractPath = R.pipe(R.split(/[?#]/), R.propOr('', '0'))

      const role = R.propOr(
        Role.Guest,
        extractPath(asPath) as string,
        restriction
      ) as number

      if (account.role < role) {
        if (isLoggedIn) {
          setRestricted(RESTRICTED_MODE.BLOCK_SCREEN)
        } else {
          setRestricted(RESTRICTED_MODE.PROMPT_LOGIN)
        }
      } else {
        setRestricted(RESTRICTED_MODE.NONE)
      }
    },
    [account.role, isLoggedIn, restriction]
  )

  useEffect(() => {
    const isGuest = R.isNil(getAccessToken())

    const isAccountLoaded = account.role !== Role.Guest

    if (isGuest || isAccountLoaded) {
      handleOnRouteChange(router.asPath)

      router.events.on('routeChangeComplete', handleOnRouteChange)

      return () => {
        router.events.off('routeChangeComplete', handleOnRouteChange)
      }
    }
  }, [account, handleOnRouteChange, router.asPath, router.events])

  useEffect(() => {
    if (restricted === RESTRICTED_MODE.PROMPT_LOGIN) {
      setShowLoginPopup(true)
    }
  }, [restricted, setShowLoginPopup])

  return (
    <>
      {restricted === RESTRICTED_MODE.BLOCK_SCREEN && (
        <BlockContainer>{t('restricted.401')}</BlockContainer>
      )}
      {restricted === RESTRICTED_MODE.NONE && children}
      {restricted === RESTRICTED_MODE.WAITING && (
        <LoadingContainer>
          <MuiLoading loading />
        </LoadingContainer>
      )}
    </>
  )
}

type Props = {
  children?: ReactNode
  restriction: Record<string, Role>
}

export default EnoughRoleOnly
