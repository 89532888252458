import {
  AdeptusCodeInput,
  AdeptusInputMode,
  ApeironBlueButton,
  ChainAddressType,
  ChainNetworkType,
  CustomError,
  ERROR_CODE,
  MayNull,
  MuiLoading,
  NextImage,
  RoninSVG,
  useAccountContext,
  useMessageContext,
  useWeb3,
  WalletExtensionRdns,
  WalletType
} from '@apeiron/library'
import { Box, styled, Typography } from '@mui/material'
import { RONIN_QRCODE_REFRESH } from '@src/constants/login'
import useMktErrorHandling from '@src/hooks/common/errorHandling/useMktErrorHandling'
import useRecursiveCalling, {
  RecursiveCallRef
} from '@src/hooks/common/tools/useRecursiveCalling'
import useWalletConnect from '@src/hooks/contract/useWalletConnect'
import { QRCodeSVG } from 'qrcode.react'
import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  background-color: #1c1f25;
  padding: 135px 40px 80px 40px;
  overflow: auto;
  ${props => props.theme.breakpoints.up('res1024')} {
    width: 560px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    width: 100%;
  }
`

const Title = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
`

const Description = styled(Typography)`
  font-size: 12px;
  text-align: center;
`

const QRContainer = styled(Box)`
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  min-width: 220px;
  max-width: 220px;
  margin: 10px auto;
`

const StyledMuiLoading = styled(MuiLoading)`
  color: black;
`

const RoninWalletIcon = styled(NextImage)`
  filter: grayscale(100%) brightness(3);
  height: 24px;
  width: 24px;
`

const QrCodeRefreshText = styled(Typography)`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
`

const RoninWalletButton = styled(ApeironBlueButton)`
  --background-color: #1473e9;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  gap: 6px;
  height: 55px;
  margin-left: auto;
  width: 100%;
  :hover {
    filter: brightness(0.9);
  }
`

const OrTextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  border-top: 1px dashed #979797;
  position: relative;
  margin: 30px auto;
  width: 100%;
`

const OrText = styled(Typography)`
  background-color: #1c1f25;
  width: 35px;
  text-align: center;
  color: white;
  top: -12px;
  position: absolute;
`

const CodeInput = styled(AdeptusCodeInput)`
  margin-top: 16px;
  width: 100%;
`

const RoninLoginSection: FC<Props> = (props: Props) => {
  const { className, isActive } = props

  const { t } = useTranslation()

  const { loginByWallet, setMissWallet, setWrongNetwork } = useAccountContext()

  const { setSnackInfo } = useMessageContext()

  const { validateWalletInstalled, getWalletConnections, disconnectWallet } =
    useWeb3()

  const { showErrorPopup } = useMktErrorHandling()

  const { roninQRCode } = useWalletConnect()

  const { connectors } = useWeb3()

  const walletConnectConnector = connectors.find(
    connector => connector.id === WalletExtensionRdns.WalletConnect
  )

  useEffect(() => {
    const connections = getWalletConnections()

    const walletConnectConnection = connections.find(connection => {
      return connection.connector.id === WalletExtensionRdns.WalletConnect
    })

    if (walletConnectConnection) {
      disconnectWallet(walletConnectConnection.connector)
    }
  }, [])

  const tick = useCallback(
    async (ref: MayNull<RecursiveCallRef>) => {
      try {
        if (!walletConnectConnector) {
          return
        }

        await loginByWallet(WalletType.RoninMobile, ChainAddressType.RONIN)
      } catch (e: unknown) {
        const error = e as CustomError

        if (ref) {
          ref.reset()
          tick(null)
        }

        showErrorPopup(error)
      }
    },
    [walletConnectConnector, loginByWallet, showErrorPopup]
  )

  const { remainSecond } = useRecursiveCalling({
    active: isActive,
    duration: RONIN_QRCODE_REFRESH,
    callback: tick
  })

  const handleOnLogin = useCallback(async () => {
    const type = WalletType.Ronin

    try {
      validateWalletInstalled(ChainNetworkType.Ronin, [WalletType.Ronin])

      await loginByWallet(type, ChainAddressType.RONIN)

      setSnackInfo({
        message: t('login.general.login_success'),
        serverity: 'success'
      })
    } catch (e) {
      const error = e as unknown as CustomError

      switch (error.code) {
        case ERROR_CODE.LOGIN.WRONG_NETWORK:
          setWrongNetwork(type)

          break

        case ERROR_CODE.LOGIN.NOT_YET_INSTALL_WALLET:
          setMissWallet(type)

          break

        default:
          showErrorPopup(error)

          break
      }
    }
  }, [
    loginByWallet,
    setMissWallet,
    setSnackInfo,
    setWrongNetwork,
    showErrorPopup,
    validateWalletInstalled,
    t
  ])

  return (
    <MainContainer className={className}>
      <Title>{t('login.wallet.title')}</Title>
      <Description>{t('login.wallet.description')}</Description>
      <QRContainer>
        {!roninQRCode ? (
          <StyledMuiLoading loading />
        ) : (
          <QRCodeSVG
            size={300}
            level='L'
            value={roninQRCode}
            imageSettings={{
              src: RoninSVG,
              excavate: true,
              width: 32,
              height: 45
            }}
          />
        )}
      </QRContainer>
      <QrCodeRefreshText>
        {t('login.qrcode.refresh', { count: remainSecond })}
      </QrCodeRefreshText>
      <OrTextContainer>
        <OrText>{t('login.wallet.or')}</OrText>
      </OrTextContainer>
      <RoninWalletButton onClick={handleOnLogin}>
        <RoninWalletIcon src={RoninSVG} />
        {t('login.general.button.ronin')}
      </RoninWalletButton>
      <CodeInput showFoot={false} mode={AdeptusInputMode.AccountLogin} />
    </MainContainer>
  )
}

type Props = {
  className?: string
  isActive: boolean
}

export default RoninLoginSection
